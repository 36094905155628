import {Col, Row} from "reactstrap";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {API_URL} from "../../config";
import React from "react";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function AttestReimbursementViews({data, modelId = null}) {
    const { t } = useTranslation();
    const isSuperAdmin = CheckUserRole('SuperAdmin'); //SuperAdmin

    if (data.fixeds && Array.isArray(data.fixeds) && data.fixeds.length > 0) {
        data.fixeds.sort((a, b) => new Date(a.salaryDate) - new Date(b.salaryDate));
    }
    return (
        <Row className="px-2 px-lg-4 justify-content-center">
            <hr className="mt-4" />
            <Col lg={8}>
                <h2 className=" rokkitt-font">{t('reimbursement.reportProcessing')}</h2>
            </Col>
            <Col lg={4} >
                <ul className="list-unstyled">
                    {isSuperAdmin === true && modelId ?
                        <>
                            <li className="pt-1 row">
                                <span className="fw-bold col-4">{t('modelLabel.modelId')}</span>
                                <span className="col-8">{modelId}</span>
                            </li>
                            <li className="pt-1 row">
                                <span className="fw-bold col-4">{t('modelLabel.userId')}</span>
                                <span className="col-8">{data.userId}</span>
                            </li>
                        </>
                    : '' }
                    {data.visualId !== "" ?
                        <li className="pt-2 row">
                            <span className="fw-bold col-4">{t('modelLabel.id')}:</span>
                            <span className="col-8">{data.visualId}</span>
                        </li>
                    : ""}
                    <li className="pt-1 pb-4 row">
                        <span className="fw-bold col-4">{t('modelLabel.userName')}</span>
                        <span className="col-8">{data.userName}</span>

                    </li>
                </ul>
            </Col>
            <Col lg={10}>
                <h5 className='text-muted'>{t('reimbursement.reportProcessing')}</h5>
            </Col>
            <Col lg={10} className="border-top border-bottom">
                <Row>
                    {isSuperAdmin === true ?
                        <Col lg={6}>
                                <ul className="list-unstyled">
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-4">{t('modelLabel.id')} </span>
                                        <span className="col-8">{data.id}</span>
                                    </li>
                                    <li className="pt-2 row">
                                        <span className="fw-bold col-4">{t('reimbursement.costBearer')} </span>
                                        <span className="col-8">{data.costBearerId}</span>
                                    </li>
                                </ul>
                        </Col>
                    : ''}
                    <Col lg={6}>
                        <ul className="list-unstyled">
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.createdAt')} </span>
                                <span className="col-8">{ChangeDateFormat('dateTime', data.createdAt)}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('reimbursement.purpose')} </span>
                                <span className="col-8">{data.purpose}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('modelLabel.date')} </span>
                                <span className="col-8">{ChangeDateFormat('date', data.date)}</span>

                            </li>
                            <li className="pt-2 row">
                                <span className="fw-bold col-4">{t('reimbursement.costBearerName')} </span>
                                <span className="col-8">{data.costBearerName}</span>

                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
            {data.fixeds && Array.isArray(data.fixeds) && data.fixeds.length > 0 ?
                <Col lg={10} className="border-top border-bottom">
                    <Row className="pt-4">
                        <h5 className="border-bottom pb-2 text-muted">{t('reimbursement.salary')}</h5>
                        {data.fixeds.map((item, index) =>
                            <Col lg={12} key={'fixedsView'+index} className={`${index !== data.fixeds.length -1 ? "border-bottom" : ''} me-2`}>
                                <Row>
                                    <Col lg={6}>
                                        <ul className="list-unstyled">
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4">{t('modelLabel.type')} </span>
                                                <span className="col-8">{item.type === "Hourly" ? t("reimbursement.movable") : t("reimbursement.fixed")}</span>
                                            </li>
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4">{t('modelLabel.date')} </span>
                                                <span className="col-8">{item.salaryDate ? ChangeDateFormat("date", item.salaryDate) : ""}</span>
                                            </li>
                                            {item.hours ?
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-4">{t('modelLabel.hours')} </span>
                                                    <span className="col-8">{item.hours}</span>
                                                </li>
                                                : ''}
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4">{t('modelLabel.amount')} </span>
                                                <span
                                                    className="col-8">{item.amount ? ToCustomDecimal(item.amount) : 0} kr </span>
                                            </li>
                                        </ul>
                                    </Col>
                                    <Col lg={6}>
                                        <ul className="list-unstyled">
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4">{t('modelLabel.employmentTax')} </span>
                                                <span className="col-8">{item.employmentTax ? ToCustomDecimal(item.employmentTax) : 0} kr</span>
                                            </li>
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4">{t('modelLabel.incomeTax')} </span>
                                                <span className="col-8">{item.incomeTax ? ToCustomDecimal(item.incomeTax) : 0} kr</span>
                                            </li>
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4">{t('modelLabel.net')} </span>
                                                <span className="col-8">{item.net ? ToCustomDecimal(item.net) : 0} kr</span>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Col>
                : ''
            }
            {data.mileage && data.mileage !== "" ?
                <Col lg={10} className="border-top border-bottom">
                    <Row className="pt-4">
                        <h5 className="border-bottom pb-2 text-muted">{t('reimbursement.mileage')}</h5>
                        <Col lg={12}>
                            <Row>
                                <Col lg={6}>
                                    <ul className="list-unstyled">
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('reimbursement.origin')} </span>
                                            <span className="col-8">{data.mileage.from}</span>
                                        </li>
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('reimbursement.destination')} </span>
                                            <span className="col-8">{data.mileage.to}</span>
                                        </li>
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('modelLabel.kilometers')} </span>
                                            <span className="col-8">{data.mileage.km} km</span>
                                        </li>
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('modelLabel.amount')} </span>
                                            <span className="col-8">{data.mileage.amount ? ToCustomDecimal(data.mileage.amount) : 0} kr</span>
                                        </li>
                                    </ul>
                                </Col>
                                <Col lg={6}>
                                    <ul className="list-unstyled">
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('modelLabel.employmentTax')} </span>
                                            <span className="col-8">{data.mileage.employmentTax ? ToCustomDecimal(data.mileage.employmentTax): 0} kr</span>
                                        </li>
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('modelLabel.incomeTax')} </span>
                                            <span className="col-8">{data.mileage.incomeTax ? ToCustomDecimal(data.mileage.incomeTax) : 0} kr</span>
                                        </li>
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4">{t('modelLabel.net')} </span>
                                            <span className="col-8">{data.mileage.net ? ToCustomDecimal(data.mileage.net) : 0} kr</span>
                                        </li>
                                    </ul>
                                </Col>
                                {data.mileage.passengers && data.mileage.passengers !== null ?
                                    data.mileage.passengers.map((item, index)=>
                                        <Col lg={6} key={'passengersView'+index}>
                                            <h5 className="text-muted">{t('reimbursement.passengers')}</h5>
                                            <ul className="list-unstyled">
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-4">{t('reimbursement.numberPassengers')} </span>
                                                    <span className="col-8">{item.quantity}</span>
                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-4">{t('modelLabel.totalDistance')} </span>
                                                    <span className="col-8"> {item.km} km </span>

                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-4">{t('modelLabel.amount')} </span>
                                                    <span className="col-8"> {item.amount ? ToCustomDecimal(item.amount) : 0} kr </span>

                                                </li>
                                            </ul>
                                        </Col>
                                    )
                                    : ''}
                            </Row>
                        </Col>
                    </Row>
                </Col>
                : ''
            }
            {data.expenses && Array.isArray(data.expenses) && data.fixeds.expenses > 0 ?
                <>
                    <Col lg={10} className="border-top border-bottom">
                        <Row className="pt-4">
                            <h5 className="border-bottom pb-2 text-muted">{t('reimbursement.expense')}</h5>
                            {data.expenses.map((item, index) =>
                                <Col lg={6} key={'expensesView'+index} className={`${index !== data.expenses.length -1 ? "border-bottom" : ''} me-2`}>
                                    <ul className="list-unstyled">
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4 pe-0">{t('modelLabel.dateReceive')} </span>
                                            <span className="col-8">{ChangeDateFormat('date', item.dateReceive)}</span>

                                        </li>
                                        {isSuperAdmin === true ?
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4 pe-0">{t('modelLabel.accountingId')} </span>
                                                <span className="col-8">{item.accountingId}</span>

                                            </li>
                                            : ''}
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4 pe-0">{t('modelLabel.accounting')} </span>
                                            <span className="col-8">{item.accountingName}</span>

                                        </li>
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4 pe-0">{t('reimbursement.amount')} </span>
                                            <span className="col-8">{item.amount ? ToCustomDecimal(item.amount) : 0} kr</span>

                                        </li>
                                        {item.vatAmount ?
                                            <li className="pt-2 row">
                                                <span className="fw-bold col-4 pe-0">{t('modelLabel.vatAmount')} </span>
                                                <span className="col-8">{item.vatAmount ? ToCustomDecimal(item.vatAmount) : 0} kr</span>
                                            </li>
                                        : ''}
                                        {isSuperAdmin === true ?
                                        <li className="pt-2 row">
                                            <span className="fw-bold col-4 pe-0">{t('modelLabel.fileId')} </span>
                                            <span className="col-8">{item.fileId} </span>
                                        </li>
                                            : ''}
                                        <li className="pt-2 d-flex align-items-center row">
                                            <span className="fw-bold col-4 pe-0">{t('reimbursement.receipt')} </span>
                                            <a href={`${API_URL}/file?id=${item.fileId}`} target="_blank" className="text-decoration-none text-green d-flex col-8">
                                                {item.fileExtension.includes('.png') || item.fileExtension.includes('.jpg') || item.fileExtension.includes('.gif') || item.fileExtension.includes('.jpeg') ? <i className="bx bx-image-alt fs-2"/> : ""}
                                                {item.fileExtension.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2"/> : ""}
                                                {item.fileExtension.includes('.doc') || item.fileExtension.includes('.docx') ? <i className="bx bxs-file-doc fs-2"/> : ""}
                                                {item.fileExtension.includes(".txt") ? <i className="bx bxs-file-txt fs-2"/> : ""}
                                                {item.fileExtension.includes(".xlsx") ? <i className="bx bxs-file fs-2"/> : ""}
                                                {item.fileExtension.includes(".xls") ? <i className="bx bxs-file fs-2"/> : ""}
                                                <span className='align-self-center ps-2'>{item.fileExtension}</span>
                                            </a>
                                        </li>
                                        {item.additionalInfo && item.additionalInfo.participantsFileId ?
                                            <>
                                            {isSuperAdmin === true ?
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-4">{t('modelLabel.additionalFileId')} </span>
                                                    <span className="col-8">{ item.additionalInfo.participantsFileId }</span>

                                                </li>
                                            : ''}
                                                <li className="pt-2 d-flex align-items-center row">
                                                    <span className="fw-bold col-4">{t('reimbursement.listParticipants')} </span>
                                                    <a href={`${API_URL}/file?id=${item.additionalInfo.participantsFileId}`} target="_blank" className="text-decoration-none text-green d-flex col-8">
                                                        {item.additionalFileExtension.includes('.png') || item.additionalFileExtension.includes('.jpg') || item.additionalFileExtension.includes('.gif') || item.additionalFileExtension.includes('.jpeg') ? <i className="bx bx-image-alt fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes('.doc') || item.additionalFileExtension.includes('.docx') ? <i className="bx bxs-file-doc fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes(".txt") ? <i className="bx bxs-file-txt fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes(".xlsx") ? <i className="bx bxs-file fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes(".xls") ? <i className="bx bxs-file fs-2"/> : ""}
                                                        <span className='align-self-center ps-2'>{item.additionalFileExtension}</span>
                                                    </a>
                                                </li>
                                            </>
                                        : ''}
                                        {item.additionalInfo && item.additionalInfo.programFileId ?
                                            <>
                                                {isSuperAdmin === true ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-4">{t('modelLabel.programFileId')} </span>
                                                        <span className="col-8">{ item.additionalInfo.programFileId }</span>

                                                    </li>
                                                    : ''}
                                                <li className="pt-2 d-flex align-items-center row">
                                                    <span className="fw-bold col-4">{t('reimbursement.listParticipants')} </span>
                                                    <a href={`${API_URL}/file?id=${item.additionalInfo.programFileId}`} target="_blank" className="text-decoration-none text-green d-flex col-8">
                                                        {item.additionalFileExtension.includes('.png') || item.additionalFileExtension.includes('.jpg') || item.additionalFileExtension.includes('.gif') || item.additionalFileExtension.includes('.jpeg') ? <i className="bx bx-image-alt fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes('.pdf') ? <i className="bx bxs-file-pdf fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes('.doc') || item.additionalFileExtension.includes('.docx') ? <i className="bx bxs-file-doc fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes(".txt") ? <i className="bx bxs-file-txt fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes(".xlsx") ? <i className="bx bxs-file fs-2"/> : ""}
                                                        {item.additionalFileExtension.includes(".xls") ? <i className="bx bxs-file fs-2"/> : ""}
                                                        <span className='align-self-center ps-2'>{item.additionalFileExtension}</span>
                                                    </a>
                                                </li>
                                            </>
                                            : ''}
                                        {item.additionalInfo && item.additionalInfo.program ?
                                            <>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-4">{t('reimbursement.programInvitation')} </span>
                                                    <span className="col-8">{item.additionalInfo.program}</span>
                                                </li>
                                            </>
                                            : ''}
                                    </ul>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </>
                : ''}
        </Row>
    );

}