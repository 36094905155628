import {Button, Card, Col, Row, Spinner} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {CustomModal} from "../../Common/Components/Modal/CustomModal";
import {ADMIN_PATHS, ORGANIZATION_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import AdminResponseOpenPaymentModel from "../Models/Response/AdminResponseOpenPaymentModel";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import AdminResponseCreatePaymentModel from "../Models/Response/AdminResponseCreatePaymentModel";
import AdminResponseCreateBasketModel from "../Models/Response/AdminResponseCreateBasketModel";
import AdminResponseAuthorisationSignBasketModel from "../Models/Response/AdminResponseAuthorisationSignBasketModel";
import AdminRequestBasketStatusModel from "../Models/Request/AdminRequestUpdateBasketStatusModel";
import AdminResponseUpdateBasketStatusModel from "../Models/Response/AdminResponseUpdateBasketStatusModel";
import AdminRequestCreateBasketModel from "../Models/Request/AdminRequestCreateBasketModel";
import AdminRequestAuthorisationSignBasketModel from "../Models/Request/AdminRequestAuthorisationSignBasketModel";
import AdminRequestCreatePaymentModel from "../Models/Request/AdminResquestCreatePaymentModel";
import $ from 'jquery';
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";
import { SaveLocalStorage } from "../../Common/Helpers/SaveLocalStorage";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import AdminRequestCreatePaymentBGPGModel from "../Models/Request/AdminRequestCreatePaymentBGPLModel";
import { CustomButton } from "../../Common/Components/Button/CustomButton";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import AdminRequestAttestDenyModel from "../../Attest/Model/Request/AdminRequestAttestDenyModel";
import { CustomInput } from "../../Common/Components/Input/CustomInput";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";

export function PendingBankTransferList ( ) {

    const { t } = useTranslation()
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();
    const { ApiCall } = useApiCallService();

    // REQUEST
    const [requestCreatePayment, setRequestCreatePayment] = useState(new AdminRequestCreatePaymentModel());
    const [requestCreatePaymentBGPG, setRequestCreatePaymentBGPG] = useState(new AdminRequestCreatePaymentBGPGModel());
    const [requestAuthorisationSignBasket, setRequestAuthorisationSignBasket] = useState(new AdminRequestAuthorisationSignBasketModel());
    const [requestUpdateBasketStatus, setRequestUpdateBasketStatus] = useState(new AdminRequestBasketStatusModel());
    const [requestCreateBasket, setRequestCreateBasket] = useState(new AdminRequestCreateBasketModel())

    //RESPONSE SEARCH
    const [responseOpenPaymentList, setResponseOpenPaymentList] = useState('');

    const [showButtonSing, setShowButtonSing] = useState(true);
    const [displaySpinner, setDisplaySpinner] = useState(false);

    //RESPONSE
    const [responseCreatePayment, setResponseCreatePayment] = useState(new AdminResponseCreatePaymentModel());
    const [responseCreateBasket, setResponseCreateBasket] = useState(new AdminResponseCreateBasketModel());
    const [responseAuthorisationSignBasket, setResponseAuthorisationSignBasket] = useState(new AdminResponseAuthorisationSignBasketModel());
    const [responseUpdateBasketStatus, setResponseUpdateBasketStatus] = useState(new AdminResponseUpdateBasketStatusModel());

    // MODAL OPTIONS
    const [displayBulkPaymentModal, setDisplayBulkPaymentModal] = useState(false);
    const [showPendingPayment, setShowPendingPayment] = useState(false);

    const [pendingStatus, setPendingStatus] = useState('');

    //CHECK PAYMENT
    const [openPaymentList, setOpenPaymentList] = useState([]);
    const [checked, setChecked] = useState([]);

    //CHECK MOUSE OVER
    const [isMouseOver, setIsMouseOver] = useState(false);

    // REVERSE PAYMENT
    const [showConfirmModalReverse, setShowConfirmModalReverse] = useState(false);
    const [errorReason, setErrorReason] = useState(false);
    const [apiRequestReversePayment, setApiRequestReversePayment] = useState(new AdminRequestAttestDenyModel());
    const [showConfirmModalApprove, setShowConfirmModalApprove] = useState(false);
    const completeSchemaDeny = AdminRequestAttestDenyModel.getAttributes( null, true);
    const [apiResponseReversePayment, setApiResponseReversePayment] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const [duplicatePaymentsList, setDuplicatePaymentsList] = useState([]);

    const handleMouseEnter = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    let permissionDeny = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.open-payment") || CheckUserPermission("admin.open-payment.deny")) {
        permissionDeny = true;
    }
    let permissionSign = false;
    if (CheckUserPermission("admin") || CheckUserPermission("admin.open-payment") || CheckUserPermission("admin.open-payment.sign")) {
        permissionSign = true;
    }

    let permissionDangerZone = false;
    if (CheckUserPermission("danger-zone") || CheckUserPermission("danger-zone.simulate")) {
        permissionDangerZone = true;
    }
    
    //DELETE LOCALSTORAGE
    const search = LoadLocalStorage("search");

    useEffect(() => {
        if (search) {
            localStorage.removeItem('search');
        }
    }, [search]);
    
    const dataCheck = (e, id) => {
        const index = responseOpenPaymentList.items.findIndex(item => item.id === id);

        if (e.target.checked === false && openPaymentList.some(item => item.id === id) ) {
            const updatedList = openPaymentList.filter(item => item.id !== id);
            setChecked(prevData => {
                return prevData.filter((item, i) => item.id !== id);
            });
            setOpenPaymentList(updatedList);
        } else {
            setChecked(prevData => {
                return [
                    ...prevData,
                    {
                        id: id,
                    }
                ];
            });
            if (index !== -1) {
                setOpenPaymentList(prevList => [...prevList, responseOpenPaymentList.items[index]]);
            }
        }

        //aqui se construyen los tados para crear el pago con (createPayment)
        let selectedItem = responseOpenPaymentList.items[index];
        let instructedAmountValue;
        let creditorNameValue;
        let creditorAccountIbanValue;
        let creditorGiroValue;
        let invoiceReference;
        let debtorAccountMessage;
        let paymentCollectionIdValue;
        let messageValue;

        if (selectedItem) {
            if(selectedItem.paymentType === 'Bankkonto'){
                
                instructedAmountValue = {
                    amount:selectedItem.instructedAmount,
                };
                creditorNameValue = selectedItem.creditorName;
                creditorAccountIbanValue = {
                    ibanorbban: selectedItem.creditorAccountIban,
                    currency:'SEK',
                    clearingNumber:selectedItem.clearingNumber,
                }
                paymentCollectionIdValue = selectedItem.id;
                messageValue = selectedItem.model;
            }else if (selectedItem.paymentType === 'Bankgiro' || selectedItem.paymentType === 'Plusgiro') {
                
                invoiceReference = selectedItem.ocr;
                debtorAccountMessage = selectedItem.debtorAccountMessage;
                instructedAmountValue = {
                    amount:selectedItem.instructedAmount,
                };
                creditorNameValue = selectedItem.creditorName;
                creditorGiroValue = {
                    giroNumber: selectedItem.creditorAccountIban,
                    giroType:selectedItem.paymentType.toUpperCase(),
                }
                paymentCollectionIdValue = selectedItem.id;
            }}                    

        if (selectedItem.paymentType === 'Bankkonto') {
            setRequestCreatePayment((prevFormData) => ({
                ...prevFormData,
                instructedAmount: instructedAmountValue,
                creditorName: creditorNameValue,
                creditorAccount: creditorAccountIbanValue,
                message:'LRF', //messageValue ,ahora solo permite 10 caracteres, mirar si eso esta bien o hay que cambiarlo porque si se le da el valor de reimbursment no funciona
                product: 'domestic',
                paymentCollectionId: paymentCollectionIdValue,
            }));
        } else if (selectedItem.paymentType === 'Bankgiro' || selectedItem.paymentType === 'Plusgiro') {
            
            setRequestCreatePaymentBGPG((prevFormData) => ({
                ...prevFormData,
                instructedAmount: instructedAmountValue,
                creditorName: creditorNameValue,
                invoiceReference: selectedItem.ocr,
                debtorAccountMessage: selectedItem.creditorAccountIban,
                creditorGiro: {
                    giroNumber: selectedItem.creditorAccountIban,
                    giroType: selectedItem.paymentType.toUpperCase(), // Asigna BANKGIRO o PLUSGIRO
                },
                paymentCollectionId: paymentCollectionIdValue,
            }));
        }
    }

    useEffect(() => {
        if (checked.length <= 0) {
            $('#checkInput').prop('checked', false);
        }
    }, [checked]);

    // REDIRECT TO INFO PAGE
    useEffect(() => {
        if (apiResponseReversePayment !== null) {   
                setShowConfirmModalReverse(false);
                FlashMessage(t('validationMessage.paymentDenied'), 'flash-messageSuccess');
            setTimeout(()=>{
                CustomReload();
            },2000);
        }
    }, [apiResponseReversePayment]);

    // PUT APPROVED
    const handleValidation = async (type) => {
        try {

            await completeSchemaDeny.validate(apiRequestReversePayment, { abortEarly: false });
            const apiCallOptions = {
                method: "delete",
                url: "/open-payment/admin",
                data: apiRequestReversePayment,
            };

            try {
                await ApiCall(apiCallOptions, setApiResponseReversePayment, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
        } catch (err) {
            setErrorReason(true);
            console.log(err)
        }
    }

    //CREATE PAYMENT
    const CreatePayment = async (item = null) => {
        try {
            const handleApicall = (response) => {
                 setResponseCreatePayment(new AdminResponseCreatePaymentModel(response))
            }
            if ((requestCreatePayment && Object.keys(requestCreatePayment.instructedAmount).length > 0 ) || item !== null) {
                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment",
                    data: item !== null ? item : requestCreatePayment,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
                if (item !== null) {
                    if(!requestCreateBasket.paymentsCollectionIds.some(itemPay => itemPay === item?.paymentCollectionId)) {
                        setRequestCreateBasket((prevData) => {
                            return {
                                ...prevData,
                                paymentsCollectionIds: [
                                    ...prevData.paymentsCollectionIds,
                                    item.paymentCollectionId
                                ]
                            }
                        })

                     }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const CreatePaymentBGPG = async (item = null) => {
        try {
            const handleApicall = (response) => {
                 setResponseCreatePayment(new AdminResponseCreatePaymentModel(response))
            }
            if ((requestCreatePaymentBGPG && Object.keys(requestCreatePaymentBGPG.instructedAmount).length > 0 ) || item !== null) {
                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment/giro",
                    data: item !== null ? item : requestCreatePaymentBGPG,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
                if (item !== null) {
                    if(!requestCreateBasket.paymentsCollectionIds.some(itemPay => itemPay === item?.paymentCollectionId)) {
                        setRequestCreateBasket((prevData) => {
                            return {
                                ...prevData,
                                paymentsCollectionIds: [
                                    ...prevData.paymentsCollectionIds,
                                    item.paymentCollectionId
                                ]
                            }
                        })

                     }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if(requestCreatePayment.paymentCollectionId !== ''){
            CreatePayment();
            if(requestCreateBasket.paymentsCollectionIds.some(item => item === requestCreatePayment?.paymentCollectionId)) {
                const updatedList = requestCreateBasket.paymentsCollectionIds.filter(item => item !== requestCreatePayment?.paymentCollectionId);
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: updatedList
                    };
                });
            } else {
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: [
                            ...prevRequestCreateBasket.paymentsCollectionIds,
                            requestCreatePayment?.paymentCollectionId
                        ]
                    };
                });
            }
            setRequestCreatePayment(new AdminRequestCreatePaymentModel());
        }

        if(requestCreatePaymentBGPG.paymentCollectionId !== ''){
            CreatePaymentBGPG();
            if(requestCreateBasket.paymentsCollectionIds.some(item => item === requestCreatePaymentBGPG?.paymentCollectionId)) {
                const updatedList = requestCreateBasket.paymentsCollectionIds.filter(item => item !== requestCreatePaymentBGPG?.paymentCollectionId);
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: updatedList
                    };
                });
            } else {
                setRequestCreateBasket((prevRequestCreateBasket) => {
                    return {
                        ...prevRequestCreateBasket,
                        paymentsCollectionIds: [
                            ...prevRequestCreateBasket.paymentsCollectionIds,
                            requestCreatePaymentBGPG?.paymentCollectionId
                        ]
                    };
                });
            }
            setRequestCreatePaymentBGPG(new AdminRequestCreatePaymentBGPGModel());
        }
    }, [requestCreatePayment,requestCreatePaymentBGPG]);

    //CREATE BASKET
    const CreateBasket = async () => {
        try {
            const handleApicall = (response) => {               
                setResponseCreateBasket(new AdminResponseCreateBasketModel(response))          
            }
            if (requestCreateBasket && requestCreateBasket.paymentsCollectionIds?.length > 0 ) {
                const apiCallOptions = {
                    method: "post",
                    url: "/open-payment/create-basket",
                    data: requestCreateBasket,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
        
    
    }

if(responseCreateBasket.basketId !== ''){
    SaveLocalStorage('basketId', responseCreateBasket.basketId);
}
    useEffect(() => {
        if (responseCreateBasket.openPaymentId !== '') {
            setRequestAuthorisationSignBasket({
                openPaymentId: responseCreateBasket.openPaymentId,
            });
            setShowButtonSing(false);

                 
        }
        if (responseCreateBasket.duplicatePayments != null && Array.isArray(responseCreateBasket.duplicatePayments) && responseCreateBasket.duplicatePayments.length > 0) {
            setDuplicatePaymentsList(responseCreateBasket.duplicatePayments);
            const updatedList = openPaymentList.filter(item => 
                !responseCreateBasket.duplicatePayments.some(duplicateItem => duplicateItem.id === item.id)
            );          
            setOpenPaymentList(updatedList);
        }  
    }, [responseCreateBasket]);
    //SIGNING BASKET
    const SigningBasket = async () => {
            try {
                const handleApicall = (response) => {
                    setResponseAuthorisationSignBasket(new AdminResponseAuthorisationSignBasketModel(response))               
                }
                
                if (requestAuthorisationSignBasket?.openPaymentId !== '') {
                    const apiCallOptions = {
                        method: "post",
                        url: "/open-payment/start-basket",
                        data: requestAuthorisationSignBasket,
                    };
                    try {
                        await ApiCall(apiCallOptions, handleApicall);
                    } catch (error) {
                        console.log(error);
                        FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                    }
                }
            } catch (err) {
                console.log(err)
            }
    }

    const SimulatePayment = async (id) => {

        try {
            const handleApicall = (response) => {
                CustomReload(`${ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST}`);            
            }
            
            if (id !== '') {
                const apiCallOptions = {
                    method: "get",
                    url: `/open-payment/simulated-payment?paymentId=${id}`,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const GetPaymentStatus = async (id, product) => {
        
        try {
            const handleApicall = (response) => {
                setPendingStatus(response.transactionStatus);
                setShowPendingPayment(true);   

                if(response.transactionStatus === 'ACCC' ||  response.transactionStatus === 'ACSC')
                setTimeout(() => {
                    CustomReload(ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST);
                }, 4000);
            }
            console.log(id)
            if(product === 'Bankgiro' || product === 'Plusgiro'){
                product = 'swedish-giro';
            }

            if (id !== '') {
                const apiCallOptions = {
                    method: "get",
                    url: `/open-payment/pending-payment-initiation-status?paymentId=${id}&product=${product}`,
                };
                try {
                    await ApiCall(apiCallOptions, handleApicall);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (responseCreateBasket.basketId !== '' && responseAuthorisationSignBasket.authorisationId !== '') {
            setRequestUpdateBasketStatus({
                basketId: responseCreateBasket.basketId,
                paymentAuthorisationId: responseAuthorisationSignBasket.authorisationId,
                authenticationMethodId: 'mbid' ,//MIRAR QUE HAY QUE PONER AHI
            })
        }

    }, [responseCreateBasket,responseAuthorisationSignBasket]);

    useEffect(() => {
        if (requestUpdateBasketStatus.basketId !== '') {
            const basketStatus = async () => {
                try {
                    const handleApicall = (response) => {
                        setResponseUpdateBasketStatus(new AdminResponseUpdateBasketStatusModel(response))
                    }
                    const apiCallOptions = {
                        method: "put",
                        url: '/open-payment/basket-update', // ver que hay que poner en authenticationMethodId
                        data: requestUpdateBasketStatus
                    };
                    await ApiCall(apiCallOptions,handleApicall );
                } catch (error) {
                    console.log('error', error);
                }
            };
            basketStatus();
        }
    }, [requestUpdateBasketStatus]);

    //REDIRECT TO PAGE FOR SIGN
    useEffect(() => {
        if (responseUpdateBasketStatus?.data !== '') {    
            const payForSign = responseUpdateBasketStatus.data;
            window.open(payForSign) ;
            setTimeout(CustomReload, 1000);
        }
        
    }, [responseUpdateBasketStatus]);

    const renderCheckColumn = (id) => {
        const index = checked.findIndex(item => item.id === id);
        return (
            <>
                <input type="checkbox" checked={index !== -1 ? true : false} onChange={(e)=>dataCheck(e, id)}/>
            </>
        );
    }

    // HANDLE DENY
    const handleChangePaymentReverseId = (id) => {
        setApiRequestReversePayment((prevFormData) => ({
            ...prevFormData,
            id: id,
        }))
    }

    // HANDLE DENY REASON
    const handleChangePaymentReverseReason = (e) => {
        if (e && e.target && e.target.value && e.target.value !== "") {
            setApiRequestReversePayment((prevFormData) => ({
                ...prevFormData,
                reason: e.target.value,
            }))
            setErrorReason(false);
        } else {
            setErrorReason(true);
        }
    }

    const renderActionColumn = (item) => {
        return (
            <>
            {permissionDangerZone === true ?
                <CustomButton icon="bx bx-dollar-circle pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('payReceive.pay')} customClasses="rounded py-2 mx-2" buttonBorder={false} id={item.id} onClick={() => {
                     SimulatePayment(item.id);
                }} />
            : ''}
            {item.status === 'Väntande' ?
                /*<CustomButton icon="bx bx-time pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={'För granskning'} customClasses="rounded py-2 mx-2" buttonBorder={false} id={item.id} onClick={() => {
                    GetPaymentStatus(item.id, item.paymentType);                
                }} />*/
                <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            GetPaymentStatus(item.id, item.paymentType);
                        }} >
                    <i className="bx bx-time pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {'För granskning' }
                    </div>
                </button>
            : ''}
            {(item.model !== 'Arvode' && item.model !== 'Skatteverket') &&  (item.status === 'Obetald' || item.status === 'Väntande') && permissionDeny === true ?
                /*<CustomButton icon="bx bx-list-minus pb-custom" iconColor="#F2495C" hoverIconColor='white' iconSize={4} tooltipText={t('attest.deny') } customClasses="rounded me-2 box-red py-2" buttonBorder={false} id={item.id} onClick={() => {
                    handleChangePaymentReverseId(item.id);
                    setShowConfirmModalReverse(true);
                }} />*/
                <button id={item.id} className="rounded me-2 py-2 border-0 text-red box-red p-custom-button position-relative class-deny"
                        onClick={() => {
                            handleChangePaymentReverseId(item.id);
                            setShowConfirmModalReverse(true);
                            const allButtons = document.querySelectorAll('button');
                            // Itera sobre cada botón y lo deshabilita
                            allButtons.forEach(button => {
                                if (button.classList.contains('class-approve')) {
                                    button.disabled = true;
                                    button.classList.remove('bg-green-light', 'text-green');
                                    button.classList.add('bg-grey-tab');
                                } else if (button.classList.contains('class-deny')) {
                                    button.disabled = true;
                                    button.classList.remove('box-red', 'text-red');
                                    button.classList.add('bg-grey-tab');
                                }
                            });
                        }} >
                    <i className="bx bx-list-minus pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('attest.deny') }
                    </div>
                </button>

            : ''}
            {/*<CustomButton icon="bx bx-show pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded py-2 mx-2" buttonBorder={false} id={item.id} onClick={() => {
                     navigate(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.id}`);
                }} />*/}
                <button id={item.id} className="rounded me-2 py-2 border-0 text-green bg-green-light p-custom-button position-relative class-approve"
                        onClick={() => {
                            navigate(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.id}`);
                        }} >
                    <i className="bx bx-show pb-custom align-middle size-icon-custom-button" />
                    <div className="tooltip-custom-button">
                        {t('button.info')}
                    </div>
                </button>
            </>
        );
    }

    const actionAllCheckColumn = async (e) => {      
        if (e.target.checked === true) {
            const updatedCheckboxes = responseOpenPaymentList.items
            .filter(item => item.status !== 'Väntande')
            .map(item  => ({
                id: item.id,
            }));
            const filteredItems = responseOpenPaymentList.items.filter(item => item.status !== 'Väntande');
            setChecked(updatedCheckboxes);
            setOpenPaymentList(filteredItems);
           
            responseOpenPaymentList.items.map(item => {
                if(item.status !== 'Väntande'){
                    if(item.paymentType == 'Bankkonto'){
                        const newRequest = new AdminRequestCreatePaymentModel();
                        newRequest.instructedAmount.amount = item.instructedAmount;
                        newRequest.creditorName = item.creditorName;
                        newRequest.creditorAccount.clearingNumber = item.clearingNumber;
                        newRequest.creditorAccount.ibanorbban = item.creditorAccountIban;
                        newRequest.creditorAccount.currency = 'SEK';
                        newRequest.product = 'domestic';
                        newRequest.message = 'Swedbank';//item.model
                        newRequest.paymentCollectionId = item.id;
                        CreatePayment(newRequest, 'Bankkonto');
                    }
                    else if(item.paymentType == 'Bankgiro'){
                        const newRequestBankGiro = new AdminRequestCreatePaymentBGPGModel();
                        newRequestBankGiro.invoiceReference = item.ocr;
                        newRequestBankGiro.debtorAccountMessage = item.creditorAccountIban;
                        newRequestBankGiro.instructedAmount.amount = item.instructedAmount;
                        newRequestBankGiro.creditorName = item.creditorName;
                        newRequestBankGiro.creditorGiro.giroNumber = item.creditorAccountIban;
                        newRequestBankGiro.creditorGiro.giroType = 'BANKGIRO';
                        newRequestBankGiro.paymentCollectionId = item.id;
                        CreatePaymentBGPG(newRequestBankGiro);
                    }
                    else if(item.paymentType == 'Plusgiro'){
                        const newRequestPlusGiro = new AdminRequestCreatePaymentBGPGModel();
                        newRequestPlusGiro.invoiceReference = item.ocr;
                        newRequestPlusGiro.debtorAccountMessage = item.creditorAccountIban;
                        newRequestPlusGiro.instructedAmount.amount = item.instructedAmount;
                        newRequestPlusGiro.creditorName = item.creditorName;
                        newRequestPlusGiro.creditorGiro.giroNumber = item.creditorAccountIban;
                        newRequestPlusGiro.creditorGiro.giroType = 'PLUSGIRO';
                        newRequestPlusGiro.paymentCollectionId = item.id;
                        CreatePaymentBGPG(newRequestPlusGiro);
                    }         
                }
                  
            });
        } else {

            const updatedCheckboxes = responseOpenPaymentList.items.map(item  => ({
                id: item.id,
            }));
            setChecked([]);
            setOpenPaymentList([]);
            setRequestCreateBasket(new AdminRequestCreateBasketModel())
        }
    }

    const renderButtonColumn = (item) => {
        return (
            <>
                <div className='pt-3'>
                    <Row className="p-0 " style={{ width: '500px' }}>
                        <Col lg={6}>
                            <div
                                onMouseEnter={ () => {
                                    if(!openPaymentList.length > 0 ){
                                        handleMouseEnter();
                                    }}
                                }
                                onMouseLeave={ () => {
                                    if(!openPaymentList.length > 0 ){
                                        handleMouseLeave();
                                    }}
                                }
                            >
                                {permissionSign === true ?
                                    <Button  id="TooltipExample" disabled={openPaymentList.length > 0 ? false : true }
                                             onClick={() => {
                                                 setDisplayBulkPaymentModal(true);
                                                 CreateBasket();
                                             }}
                                             className="bg-grey-tab text-muted border-0 fw-bold position-relative">
                                        {t('payReceive.sign')}
                                        <div className={`tooltip-grey ${isMouseOver === true ? 'opacity-1' : ''}`}>{t('validationMessage.selectPayment')} </div>
                                    </Button>
                                    : ''}
                            </div>
                        </Col>
                    </Row>
                </div>

            </>
        );
    }

    const ignoreFields = ["Id", "Roles", "Description"];
    const columns = ["visualId", 'model','creditorName','creditorAccountIban','paymentType','createdAt', 'status', 'instructedAmount'];
    const listOptions = {
        "model": AdminResponseOpenPaymentModel,
        'columns':[
            "visualId", 'model','creditorName','creditorAccountIban','paymentType','createdAt', //añadir product, creditorAccountIban,instructedAmount
            {
                "field" : "status",
                "label" : t('modelLabel.status'),
                'insideClass' : (item)=> item.status === 'Betald' ? 'box-green' : item.status === 'Väntande' ? 'box-orange' :'box-red ',
                "value": (item) =>  item.status === 'Betald' ? t('modelLabel.paidOut') : item.status === 'Väntande' ? t('attest.pending') : t('modelLabel.unpaid') || "",
                //'thClass': 'ps-5'
            },
            {
                "field" : "instructedAmount",
                "label" : t('modelLabel.amount'),
                'insideClass' : (item)=> item.instructedAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.instructedAmount ? ToCustomDecimal(item.instructedAmount) +' kr' : "",
                'thClass': 'ps-5'
            },

        ],
        "actions": {
            "actionColumn" : renderActionColumn,
            "addButton": renderButtonColumn,

        },
        "pagination": {
            "viewDropdownPerPage": false,
            "controlsView": false,
            "initialItemsPerPage": 1000
        },
        "table":{
            "paginationInFooter": false,
        }, 
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/open-payment/admin/search-unpaid",
        "nameFile": t('payReceive.bankAccount'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/open-payment/admin/search-unpaid",
    };

    if (permissionSign === true) {
        listOptions.actions.checkColumn = renderCheckColumn;
        listOptions.actions.actionAllCheckColumn = actionAllCheckColumn;
        listOptions.actions.allCheckColumn = true;
    }

    //MODAL CONTENT
    const modalContent = (
        <div>
            <h4 className="rokkitt-font">{t('openPayment.bulkPayment')}</h4>          
            {openPaymentList.map((item, index) => {
                return (
                    <Card className="bg-card-grey py-2 px-4 text-start mb-4" key={'payment' + item.modelId}>
                        <div><span className="fw-bold">{t('modelLabel.model')}:</span> {item.model === 'Förrättning' ? t('reimbursement.reportProcessing') : item.model === 'Arvode' ? t('honorarium.honorarium') : item.model === 'Betala' ? t('payReceive.pay') : item.model === 'Intern transaktion' ? t('internalTransaction.internalTransaction') : item.model === 'Skatteverket' ? item.type : ''} </div>
                        <div><span className="fw-bold">{t('openPayment.instructedAmount')}: </span> {item.instructedAmount ? ToCustomDecimal(item.instructedAmount) : 0} kr</div>
                        <div><span className="fw-bold">{t('openPayment.creditorName')}:</span> { item.model === 'Skatteverket' ? t('modelLabel.TheSwedishTaxAgency'): item.creditorName} </div>
                        {item.creditorAccountIban ?
                            <div><span className="fw-bold">{t('openPayment.creditorAccountIban')}:</span> {item.creditorAccountIban} </div>
                            : ''}
                        {item.creditorAccountBban ?
                            <div><span className="fw-bold">{t('openPayment.creditorAccountBban')}:</span> {item.creditorAccountBban} </div>
                            :''}
                        <div><span className="fw-bold">{t('modelLabel.status')}:</span> {item.status === 'Betald' ? t('modelLabel.paid') : item.status === 'Obetald' ? t('modelLabel.unpaid') : item.status} </div>
                    </Card>
                );
            })}
            {duplicatePaymentsList && duplicatePaymentsList.length > 0 && (
                duplicatePaymentsList.map((item, index) => {
                    return (
                        <Card className="bg-card-grey-duplicate py-2 px-4 text-start mb-4" key={'payment' + item.modelId}>
                            <div><span className="fw-bold"></span>  </div>
                            <div><span className="fw-bold">{'Id'}:</span> {item.visualId} </div>
                            <div><span className="fw-bold">{t('modelLabel.model')}:</span> {item.model === 'Förrättning' ? t('reimbursement.reportProcessing') : item.model === 'Arvode' ? t('honorarium.honorarium') : item.model === 'Betala' ? t('payReceive.pay') : item.model === 'Intern transaktion' ? t('internalTransaction.internalTransaction') : item.model === 'Skatteverket' ? item.type : ''} </div>
                            <div><span className="fw-bold">{t('openPayment.instructedAmount')}: </span> {item.amount ? ToCustomDecimal(item.amount) : 0} kr</div>
                            <div><span className="fw-bold">{t('openPayment.creditorName')}:</span> {item.name} </div>
                            {item.bankAccount ? (
                                <div><span className="fw-bold">{t('openPayment.creditorAccountIban')}:</span> {item.bankAccount} </div>
                            ) : null}                  
                            <div style={{ marginTop: '1rem' }}>
                            {item.status && item.status === 'Väntande' ? (
                                <div className="text-red-pending d-flex align-items-center mb-3">
                                    <i className='bx bx-info-circle fs-5 pe-2'/>{t('infoBox.duplicatePayment')}
                                </div>
                            ): 
                                <div className="text-red-pending d-flex align-items-center mb-3">
                                    <i className='bx bx-info-circle fs-5 pe-2'/>{t('infoBox.duplicatePaymentStatus')}
                                </div>
                            }
                            </div>
                        </Card>
                     );
                 })
            )}
        </div>
    );
    
    const modalFooter = (
        <>
         {displaySpinner === true ?
            <Col lg='12' className="text-center">
                {t('validationMessage.sendForm')} <Spinner color='success' className="me-3" />
            </Col>
            : ""}
            <Button className="box-green border-0" disabled={showButtonSing} onClick={()=> {
                SigningBasket();
                setDisplaySpinner(true)
                setShowButtonSing(true)
            }}>
                {t('openPayment.sign')}
            </Button>
            <Button className="box-red border-0" onClick={() =>{
                setDisplayBulkPaymentModal(false);
                $("#TooltipExample").prop("disabled", false);
            }}>{t('button.cancel')}</Button>
        </>
    );

    //PENDING MODAL CONTENT
    const pendingModalContent = (
        <>      
            <Col lg='12' className="text-center">
                <h4 className="rokkitt-font">{t('modelLabel.status')}</h4>
                <h4>{pendingStatus}</h4>
                {pendingStatus === 'ACSP' ? 
                    <p className="mt-4">ACSP: ’AcceptedSettlementInProcess’ - Alla föregående kontroller såsom teknisk validering och kundprofil var framgångsrika och därför har betalningsinitieringen accepterats för genomförande.</p> :
                 pendingStatus === 'ACTC' ? 
                    <p className="mt-4">ACTC: 'AcceptedTechnicalValidation' - Autentisering samt syntaktisk och semantisk validering har genomförts.</p> : 
                pendingStatus === 'ACWC' ? 
                    <p className="mt-4">ACWC: 'AcceptedWithChange' - Instruktionen är accepterad men en ändring kommer att göras, t.ex. att datum eller remiss inte skickas.</p> : 
                pendingStatus === 'ACWP' ? 
                    <p className="mt-4">ACWP: 'AcceptedWithoutPosting' - Betalningsinstruktionen som ingår i gireringen accepteras utan att bokföras på den kreditgivande kundens konto.</p> : 
                pendingStatus === 'RCVD' ? 
                    <p className="mt-4">RCVD: 'Received' - Betalningsinitieringen har tagits emot av det mottagande ombudet.</p> : 
                pendingStatus === 'PDNG' ? 
                    <p className="mt-4">PDNG: 'Pending' - Betalningsinitiering eller enskild transaktion som ingår i betalningsinitieringen är under behandling. Ytterligare kontroller och statusuppdatering kommer att utföras.</p> : 
                pendingStatus === 'ACFC' ? 
                    <p className="mt-4">ACFC: 'AcceptedFundsChecked' - Föregående kontroll av teknisk validering och kundprofil var framgångsrik och en automatisk kontroll av medel var positiv. Anmärkning: Denna kod accepteras som ny kod av ISO20022.</p> :
                pendingStatus === 'PATC' ? 
                    <p className="mt-4">PATC: 'PartiallyAcceptedTechnical' Correct Betalningsinitieringen behöver flera autentiseringar, där vissa men ännu inte alla har utförts. Syntaktiska och semantiska valideringar är framgångsrika. Anmärkning: Denna kod accepteras som ny kod av ISO20022.</p> :
                pendingStatus === 'PART' ? 
                    <p className="mt-4">PART: 'PartiallyAccepted' - Ett antal transaktioner har accepterats, medan ett annat antal transaktioner ännu inte har uppnått statusen 'accepterad'. Anmärkning: Denna kod får endast användas vid massbetalningar. Den används endast i en situation där alla föreskrivna behörigheter har tillämpats, men vissa betalningar har avvisats.</p> :
                    pendingStatus === 'ACCC' ? 
                    <p className="mt-4">ACCC: ’AcceptedSettlementCompleted’ - Avräkning på fordringsägarens konto har slutförts.</p> :
                    pendingStatus === 'ACSC' ? 
                    <p className="mt-4">ACSC: 'AcceptedSettlementCompleted' - Settlement on the debtor's account has been completed.</p> : "" }
            </Col> 
        </>
    );

    const pendingModalFooter = (
        <>
          <Button className="box-green border-0" onClick={() => setShowPendingPayment(false)}>{t('button.hideInfo')}</Button>
        </>
    );
    const contentBodyDeny = (
        <>
            <div className="text-center pb-3">
                <h5 className="rokkitt-font">{t('validationMessage.modalPaymentDenyHeader')}</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-75 position-relative">
                    <CustomInput model={AdminRequestAttestDenyModel} attribute="reason" customChange={e => handleChangePaymentReverseReason(e)}/>
                    <div className={`error tooltip-custom-normal ${errorReason === true ? 'opacity-1' : 'opacity-0'}`}
                        style={{color: '#f56e6e'}}>
                        {t('validationMessage.fieldRequired')}
                    </div>
                </div>
            </div>
            <div>{t('validationMessage.modalPaymentDeny')}</div>
        </>
    );

    const contentFooterDeny = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await handleValidation("deny");
            }}>{t('button.accept')}</Button>
            <Button className="box-red border-0" onClick={() => {
                setShowConfirmModalReverse(false);
                setErrorReason(false);
                const allButtons = document.querySelectorAll('button');
                // Itera sobre cada botón y lo deshabilita
                allButtons.forEach(button => {
                    if (button.classList.contains('class-approve')) {
                        button.disabled = false;
                        button.classList.add('bg-green-light', 'text-green');
                        button.classList.remove('bg-grey-tab');
                    } else if (button.classList.contains('class-deny')) {
                        button.disabled = false;
                        button.classList.add('box-red', 'text-red');
                        button.classList.remove('bg-grey-tab');
                    }
                });

            }}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
            <Col lg={12} className=' pb-5 px-0'>
                <CustomModal isOpen={showPendingPayment} toggle={() => setShowPendingPayment(!showPendingPayment)}
                             body={pendingModalContent} footer={pendingModalFooter}/>
                 <CustomModal isOpen={showConfirmModalReverse} toggle={() => setShowConfirmModalReverse(!showConfirmModalReverse)} body={contentBodyDeny} footer={contentFooterDeny} />
                             
                <CustomModal isOpen={displayBulkPaymentModal} toggle={() => setDisplayBulkPaymentModal(!displayBulkPaymentModal)}
                             body={modalContent} footer={modalFooter}/>
                <Card className='card-backend p-4'>
                    <Row>
                        <Col lg={6}>
                            <h2 className="fw-bold rokkitt-font">{t('openPayment.pendingBankList')}</h2>
                        </Col>
                        <Col lg={6} className="text-end">
                            <Button className="bg-green" onClick={() => {
                                    navigate(`${ADMIN_PATHS.BANK_TRANSFERS_HISTORY_LIST}`);
                                }}>
                                {t('openPayment.history')}
                            </Button>
                        </Col>
                    </Row>

                    <SortableList
                        listOptions={listOptions}
                        setApiCallDone={setResponseOpenPaymentList}
                    />
                    {/*<div className='position-custom-button-add bottom-0 pb-4 pt-4 pt-lg-0'>
                        <Row className="p-0 " style={{ width: '500px' }}>
                            <Col lg={6}>
                                <div
                                    onMouseEnter={ () => {
                                        if(!openPaymentList.length > 0 ){
                                            handleMouseEnter();
                                        }}
                                    }
                                    onMouseLeave={ () => {
                                        if(!openPaymentList.length > 0 ){
                                            handleMouseLeave();
                                        }}
                                    }
                                >
                                    {permissionSign === true ?
                                         <Button  id="TooltipExample" disabled={openPaymentList.length > 0 ? false : true }
                                                 onClick={() => {
                                                     setDisplayBulkPaymentModal(true);
                                                     CreateBasket();
                                                     $("#TooltipExample").prop("disabled", true);
                                                 }}
                                                 className="bg-grey-tab text-muted border-0 fw-bold position-relative">
                                            {t('payReceive.sign')}
                                            <div className={`tooltip-grey ${isMouseOver === true ? 'opacity-1' : ''}`}>{t('validationMessage.selectPayment')} </div>
                                         </Button>
                                    : ''}
                                </div>
                            </Col>
                        </Row>
                    </div>*/}
                </Card>
            </Col>
        </>
    );
}