import {useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {Col, Row} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import AdminResponseOrgModel from "../Models/Response/AdminResponseOrgModel";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";

export function AdminOrganization() {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { t } = useTranslation()
    //const [filter, setFilter] = useState("");
    //const { ApiCall } = useApiCallService();
    //const [apiResponse, setApiResponse] = useState(null);
    const navigate = useNavigate();


   /* const fetchItems = async (params, currentPage, itemsPerPage) => {
        let url = '/org/admin/search'
        url += `?page=${currentPage}&perPage=${itemsPerPage}`;

        const finalUrl = UrlStructure({params,filter,url});

        const apiCallOptions = {
            method: "get",
            url: finalUrl,
        };
        try {
            await ApiCall(apiCallOptions, setApiResponse);
            return apiResponse;
        } catch (error) {
            console.log(error);
        }
    };
    const handleFilterChange = (newFilters) => {
        // GLOBAL SEARCH
        setFilter(`searchCriteria=${newFilters.searchCriteria}`);

    };*/
    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false}
                              id={item.id}
                              onClick={() => {
                                  //setIndividualUserId(item);
                                  const newUrl = `${ADMIN_PATHS.ADMIN_ORGANIZATION_INFO}?id=${item.id}`;
                                  navigate(newUrl);
                              }}
                />
            </>
        );
    }
    const columns = ["visualId", 'name', 'type', 'createdAt'];

    if (isSuperAdmin === true) {
        columns.unshift('id');
    }
    const listOptions = {
        "model": AdminResponseOrgModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/org/admin/search",
        "nameFile": t('modelLabel.org'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/org/admin/search",
    };


    return(
        <>
            <Col lg="12" className='card card-backend p-4 mb-5'>
                <h2 className="pb-2 rokkitt-font">{t('adminLabel.organizations')}</h2>
                <SortableList
                   // fetchDataFunction={fetchItems}
                    listOptions={listOptions}
                   // handleFilterChange={handleFilterChange}
                    //type="model"
                />
            </Col>

        </>);
}