import {Card, Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";
import {AttestHonorariumViews} from "../../Attest/Components/AttestHonorariumViews";
import {AttestReimbursementViews} from "../../Attest/Components/AttestReimbursementViews";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {AttestPayViews} from "../../Attest/Components/AttestPayViews";

export function AccountRowView({data}) {
    const { t } = useTranslation();
    const [moreInfo, setMoreInfo] = useState(false);

    return(
        <>
            <Col lg={12} className="pb-5 px-0">
                <Card className="card-backend p-4">
                    <Row>
                        <Col xs={12} lg={6}>
                            <h2 className="rokkitt-font">{t('digitalAccount.accountRowInfo')}</h2>
                        </Col>
                        {data !== null ?
                            <Col lg={12}>
                                <Card className="p-3">
                                    <Row className="px-2 px-lg-4">
                                        <Col xs={12} lg={8}>
                                            <ul className="list-unstyled">
                                                {data.visualId !== "" ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-2">{t('modelLabel.id')}:</span>
                                                        <span className="col-6 col-lg-10">{data.visualId}</span>

                                                    </li>
                                                : ""}
                                                {data.model === "Intern transaktion" ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-2">{t('modelLabel.createdBy')}:</span>
                                                        <span className="col-6 col-lg-10">{data.createdByName}</span>

                                                    </li>
                                                : ""}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-2">{t('modelLabel.createdAt')}:</span>
                                                    <span className="col-6 col-lg-10 pe-0">{ChangeDateFormat('dateTime',data.createdAt)}</span>

                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-2">{t('modelLabel.amount')}:</span>
                                                    <span className="col-6 col-lg-10">{data.amount ? ToCustomDecimal(data.amount) : 0} kr</span>

                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-2">{t('digitalAccount.swedbankFee')}:</span>
                                                    <span className="col-6 col-lg-10">{data.swedBankFee ? ToCustomDecimal(data.swedBankFee) : 0} kr</span>

                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-2">{t('modelLabel.balance')}:</span>
                                                    <span className="col-6 col-lg-10">{data.balance ? ToCustomDecimal(data.balance): 0} kr</span>

                                                </li>
                                                <li className="pt-4 row">
                                                    <div className="fw-bold col-6 col-lg-2">{t('modelLabel.description')}:</div>
                                                    <span className="col-6 col-lg-10">
                                                         {data !== null &&  data.description === 'Användarlön' ? t('modelLabel.userSalary') : data.description === 'Innehållen skatt' ? t('modelLabel.incomeTax') : data.description === 'Arbetsgivaravgift' ? t('modelLabel.employmentTax') : data.description }
                                                    </span>
                                                </li>
                                                {data.message && data.message !== "" ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-2">{t('modelLabel.message')}:</span>
                                                        <span className="col-6 col-lg-10">{data.message}</span>

                                                    </li>
                                                : ""}
                                            </ul>
                                        </Col>
                                        {data.model === "Arvode" || data.model === "Förrättning" || data.model === "Betala" ?
                                            <Col lg={12} className="">
                                                <CustomButton text={moreInfo === true ? t('button.moreInfo') : t('button.hideInfo') } customClasses="rounded border-0" onClick={() => setMoreInfo(!moreInfo)}/>
                                            </Col>
                                        : ""}
                                    </Row>
                                    {moreInfo === true ?
                                        <>
                                            {data.model === "Arvode" ?
                                                <AttestHonorariumViews data={data.modelInfo} modelId={data.modelId}/>
                                            : ""}
                                            {data.model === "Förrättning" ?
                                                <AttestReimbursementViews data={data.modelInfo} modelId={data.modelId}/>
                                            : ""}
                                            {data.model === "Betala" ?
                                                <AttestPayViews data={data.modelInfo} modelId={data.modelId}/>
                                            : ""}
                                        </>
                                    : ''}
                                </Card>
                            </Col>
                        : ''}
                    </Row>
                </Card>
            </Col>

        </>
    );
}