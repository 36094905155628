import {Card, Col, Row, Spinner} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {ChangeDateFormat} from "../../Common/Helpers/ChangeDateFormat";

export function CustomReimbursementView({data, costSelected = null, orgSelected = null}) {
    const { t } = useTranslation();
    return(
        <>
            <Card className="bg-card-grey p-3 mb-2">
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col xl={12} className="order-1 order-xxl-0">
                                {(data?.status !== '' && data?.status !== "Förslag") || (data?.orgName && data?.orgName !== "") || (orgSelected !== null) ?
                                    <Row className='pb-3'>
                                        {data?.status !== '' && data?.status !== "Förslag" ?
                                            <Col lg={8} className='fw-medium'>
                                                {data?.userName}
                                            </Col>
                                        : ''}
                                        { orgSelected !== null  ?
                                            <Col lg={4} className="fw-medium">
                                                {orgSelected}
                                            </Col>
                                            :
                                            data?.orgName && data?.orgName !== ""?
                                                <Col lg={4} className="fw-medium">
                                                    {data?.orgName}
                                                </Col>
                                        : ''}
                                    </Row>
                                : ''}
                                <Row>
                                    <Col lg={5} >
                                        {data?.purpose}
                                    </Col>
                                    <Col lg={3} className="d-flex align-items-center ps-lg-0">
                                        {ChangeDateFormat('date', data?.date)}
                                    </Col>
                                    <Col lg={3} className="d-flex align-items-center ps-lg-0">
                                        {ChangeDateFormat('date', data?.toDate)}
                                    </Col>
                                    <Col lg={4} className="d-flex align-items-center">
                                        { costSelected !== null  ?
                                            costSelected
                                            :
                                            data?.costBearerName && data?.costBearerName !== "" ?  data?.costBearerName: ''
                                        }
                                    </Col>
                                </Row>
                                {/*Previous design just in case, delete when the new one is approved*/}
                                {/*{requestReimbursementFixed?.createdAt ?*/}
                                {/*    <Row className="pb-2">*/}
                                {/*        <Col lg={5}>*/}
                                {/*            <h6>{t('modelLabel.createdAt')}:</h6>*/}
                                {/*        </Col>*/}
                                {/*        <Col lg={7} className="text-start text-lg-end">*/}
                                {/*            {requestReimbursementFixed?.date} Mirar si aqui hay que poner la fecha de cuando se creo*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*    : ''}*/}
                                {/*<Row className="pb-2">*/}
                                {/*    <Col lg={5}>*/}
                                {/*        <h6>{t('modelLabel.date')}:</h6>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                {/*        {data?.date}*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row className="pb-2">*/}
                                {/*    <Col lg={5}>*/}
                                {/*        <h6>{t('reimbursement.purpose')}:</h6>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                {/*        {data?.purpose}*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                                {/*<Row>*/}
                                {/*    <Col lg={5}>*/}
                                {/*        <h6>{t('reimbursement.costBearer')}:</h6>*/}
                                {/*    </Col>*/}
                                {/*    <Col lg={7} className="text-start text-lg-end">*/}
                                {/*        {costSelected}*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

        </>
    );

}