import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Spinner} from "reactstrap";
import useApiCallService from "../../Common/Helpers/ApiCall";
import ResponseReimbursement from "../Models/Response/ResponseReimbursement";
import ResponseReimbursementFixed from "../Models/Response/ResponseReimbursementFixed";
import ResponseReimbursementMileage from "../Models/Response/ResponseReimbursementMileage";
import ResponseReimbursementExpense from "../Models/Response/ResponseReimbursementExpense";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import { REPORT_PROCEEDING_PATHS} from "../../config";
import ResponseUserModel from "../../User/Models/Response/ResponseUserModel";
import {useTranslation} from "react-i18next";
import {CustomReimbursementView} from "../Components/CustomReimbursementView";
import {CustomReimbursementFixedView} from "../Components/CustomReimbursementFixedView";
import {CustomReimbursementMileageView} from "../Components/CustomReimbursementMileageView";
import {CustomReimbursementExpenseView} from "../Components/CustomReimbursementExpenseView";
import $ from "jquery";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";

const ReportProceedingInfo = () => {
    const { t } = useTranslation()
    const { ApiCall } = useApiCallService();
    const navigate = useNavigate();

    const [user, setUser] = useState(new ResponseUserModel());
    const [reimbursement, setReimbursement] = useState(new ResponseReimbursement());
    const [reimbursementFixed, setReimbursementFixed] = useState(new ResponseReimbursementFixed());
    const [reimbursementMileage, setReimbursementMileage] = useState(new ResponseReimbursementMileage());
    const [reimbursementExpense, setReimbursementExpense] = useState(new ResponseReimbursementExpense());
    const [duplicateReimbursement, setDuplicateReimbursement] = useState(null);

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? "";

    // GET REIMBURSEMENT
    useEffect(() => {
        if (id !== "") {
            const handleApiResponse = (apiResponse) => {
                setReimbursement(apiResponse);
                setReimbursementFixed(apiResponse.fixeds);
                setReimbursementMileage(apiResponse.mileage);
                setReimbursementExpense(apiResponse.expenses);
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/reimbursement?id=${id}`
                    };
                    await ApiCall(apiCallOptions, handleApiResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, []);

    // GET USER
    useEffect(() => {
        if (reimbursement.userId !== "") {
            const handleApiResponse = (apiResponseUser) => {
                setUser(apiResponseUser);
            };
            const fetchData = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/user?id=${reimbursement.userId}`
                    };
                    await ApiCall(apiCallOptions, handleApiResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            fetchData();
        }
    }, [reimbursement]);

    // HANDLE DUPLICATE
    const HandleDuplicate = async () => {
        $("#duplicateButton").prop("disabled", true);
        $("#spinner").removeClass("d-none");
        try {
            const apiCallOptions = {
                method: "post",
                url: `/reimbursement/duplicate`,
                data: {
                    id: reimbursement.id
                }
            };
            await ApiCall(apiCallOptions, setDuplicateReimbursement);
        } catch (error) {
            console.log('error', error);
        }
    }

    // DUPLICATE REDIRECT
    useEffect(() => {
        if (duplicateReimbursement !== null && duplicateReimbursement.id !== "") {
            setTimeout(() => {
                $("#duplicateButton").prop("disabled", false);
                navigate(`${REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING}?id=${duplicateReimbursement.id}`)
            }, 2000);
        } else {
            $("#duplicateButton").prop("disabled", false);
            $("#spinner").addClass("d-none");
        }
    }, [duplicateReimbursement]);

    return (
        <Col lg={12} className="p-0">
            <Row>
                <Col lg={1}>
                    <CustomButton
                        text={t('button.back')}
                        icon={'bx bx-chevron-left'}
                        buttonSize={'small'}
                        customClasses={'mb-3 ms-3'}
                        onClick={() => {
                            navigate(REPORT_PROCEEDING_PATHS.REPORT_PROCEEDING_LIST)
                        }}
                    />
                </Col>
            </Row>
            <Row className="pb-5 d-flex justify-content-center">
                {reimbursement.id !== "" ?
                    <>
                        <Col xs={12} lg={6}>
                            <Card className="card-backend p-4 position-relative">
                                {reimbursement.visualId !== "" ?
                                    <div className="position-absolute end-0 mt-4 me-4 top-0 fs-5">{reimbursement.visualId}</div>
                                : ""}
                                <Row>
                                    <Col lg={12} className="text-center">
                                        <h2 className="rokkitt-font">{t('reimbursement.reportProcessing')}</h2>
                                    </Col>
                                    <Col lg={12}>
                                        <CustomReimbursementView data={reimbursement}/>
                                    </Col>
                                    {reimbursementFixed && reimbursementFixed.length > 0 ?
                                        <Col lg={12}>
                                            <h5 className='rokkitt-font mt-2'>{t('reimbursement.salary')}</h5>
                                            <CustomReimbursementFixedView data={reimbursement.fixeds}/>
                                        </Col>
                                    : ""}
                                    {reimbursementMileage && reimbursementMileage.id !== "" ?
                                        <Col lg={12} className="pt-4">
                                            <h5 className='rokkitt-font mt-2'>{t('reimbursement.mileage')}</h5>
                                            <CustomReimbursementMileageView data={reimbursement.mileage}/>
                                        </Col>
                                    : ""}
                                    {reimbursementExpense && reimbursementExpense.length > 0 ?
                                        <Col lg={12} className="pt-4">
                                            <h5 className='rokkitt-font mt-2'>{t('reimbursement.expenseTitle')}</h5>
                                            <CustomReimbursementExpenseView data={reimbursement.expenses}/>

                                        </Col>
                                        : ""}
                                </Row>
                            </Card>
                        </Col>
                        {reimbursement.approvedDeniedByName && reimbursement.approvedDeniedByName !== "" && (reimbursement.status === "Nekad" || reimbursement.status === "Godkänd" || reimbursement.status === "Utbetald") ?
                            <Col xs={12} lg={6}>
                                <Card className="card-backend p-4">
                                    <Row>
                                        <Col lg={12} className="text-center">
                                            <h2 className={`${reimbursement.status === "Nekad" ? "text-red" : "text-green"} rokkitt-font`}>{reimbursement.status === "Nekad" ? t('attest.denied') : t("attest.approved")}</h2>
                                        </Col>
                                        <Col lg={12}>
                                            <Card className="bg-card-grey p-3">
                                                <h5 className='rokkitt-font'>{t('modelLabel.approvedDeniedByName')}</h5>
                                                {reimbursement.approvedDeniedByName}
                                                {reimbursement.reason ?
                                                    <>
                                                        <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                                        {reimbursement.reason}
                                                    </>
                                                : ""}
                                            </Card>
                                        </Col>
                                        {!reimbursement.newReimbursementId && reimbursement.status === "Nekad" ?
                                            <Col lg={12} className="text-center mt-3">
                                                {t('reimbursement.deniedMessage')}
                                                <br/>
                                                <div id="spinner" className="d-none">{t('validationMessage.sendForm')} <Spinner color='success' className="me-3"/></div>
                                                <Button id="duplicateButton" className="bg-green-light text-green border-0" onClick={() => {
                                                    HandleDuplicate();
                                                }}>
                                                    {t("button.create")}
                                                </Button>
                                            </Col>
                                        : ""}
                                        {reimbursement.status === "Utbetald" ?
                                            <Row>
                                                <Col lg={12} className="text-center mt-5">
                                                    <h2 className="text-green rokkitt-font">{ t('attest.paymentInformation')}</h2>
                                                </Col>
                                                <Col lg={12}>
                                                    <Card className="bg-card-grey p-3">
                                                        <Row>
                                                            <Col>
                                                                <h5 className='rokkitt-font'>{t('attest.recipientAccount')}</h5>
                                                                {reimbursement.recipientAccount} 
                                                            </Col>
                                                            <Col>
                                                                <h5 className='rokkitt-font'>{t('attest.paidDateTime')}</h5>
                                                                {ChangeDateFormat('dateTime', reimbursement.paidDateTime)} 
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col> 
                                             </Row>
                                        : ""}  
                                    </Row>
                                </Card>
                            </Col>
                        : ""}                   
                    </>
                    : ""}
            </Row>
        </Col>
    );
};

export default ReportProceedingInfo;