import {useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {useNavigate} from "react-router-dom";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import ResponseUserModel from "../Models/Response/ResponseUserModel";
import {Col, Row} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import {useTranslation} from "react-i18next";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {UrlStructure} from "../../AppSystem/Helpers/UrlStructure";

export function AdminUsers() {
    const isSuperAdmin = CheckUserRole("SuperAdmin");
    const { t } = useTranslation()
    const navigate = useNavigate();

    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false}
                              id={item.id}
                              onClick={() => {
                                  //setIndividualUserId(item);
                                  const newUrl = `${ADMIN_PATHS.ADMIN_USER_INFO}?id=${item.id}`;
                                  navigate(newUrl);
                              }}
                />
            </>
        );
    }
    const columns = ["visualId", 'firstName','lastName','email','phone',"loggedOnLastTime"];

    if (isSuperAdmin === true) {
        columns.unshift('id');
    }
    const listOptions = {
        "model": ResponseUserModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/user/admin/search",
        "nameFile": t('modelLabel.user'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/user/admin/search",
        "modelType" : "AdminUsers"
    };


    return(
        <>
            <Col lg="12" className='card card-backend p-4 mb-5'>
                <h2 className="pb-2 rokkitt-font">{t('adminLabel.users')}</h2>
                <SortableList
                    listOptions={listOptions}
                />
            </Col>
        </>);

}