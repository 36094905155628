import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import SortableList from "../../Common/Components/Lists/SortableList";
import {Col, Row} from "reactstrap";
import ResponseDigitalAccountModel from "../Models/Response/ResponseDigitalAccount";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {CheckUserRole} from "../../AppSystem/Helpers/CheckUserRole";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";
import {LoadLocalStorage} from "../../Common/Helpers/LoadLocalStorage";
import {CardDigitalAccount} from "../Components/CardDigitalAccount";

export function AdminDigitalAccountList() {
    const { t } = useTranslation();
    const {ApiCall} = useApiCallService();
    const navigate = useNavigate();
    const search = LoadLocalStorage("search");

    // API RESPONSES
    const [responseAllDigitalAccount, setResponseAllDigitalAccount] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const [done, setDone] = useState(null);
    const [changedFilter, setChangedFilter] = useState("true");

    useEffect(() => {
        if (search) {
            setSearchValue(search);
            setDone(true);
            localStorage.removeItem('search');
        }
    }, [search]);

    const ResponseCheck = () => {
        setChangedFilter("loading");
        setTimeout(() => {
            setChangedFilter("true");
        }, 1000);
    }

    //GET TOTAL DIGITAL ACCOUNT BALANCE
    useEffect(() => {
        if (done === true || done === null) {
            setDone(false);
            const handleAllDigitalAccountResponse = (responseAllAccountRowList) => {
                if (responseAllAccountRowList !== null) {
                    setResponseAllDigitalAccount(responseAllAccountRowList);
                }
            };
            const AllDigitalAccountApiCall = async () => {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: "/digital-account/admin/total-all-account?" + searchValue,
                    };
                    await ApiCall(apiCallOptions, handleAllDigitalAccountResponse);
                } catch (error) {
                    console.log('error', error);
                }
            };
            AllDigitalAccountApiCall();
        }

    }, [done]);

    const renderActionColumn = (item) => {
        return (
            <>
                <CustomButton icon = "bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded" buttonBorder={false} id={item.id} onClick={() => {
                    navigate(`${ADMIN_PATHS.DIGITAL_ACCOUNT_VIEW}?id=${item.id}`);
                }} />
            </>
        );
    }
    const isSuperAdmin = CheckUserRole('SuperAdmin');
    const columns = [
        "visualId",
        "orgName",
        "orgType",
        "costPlace",
        "updatedAt",
        'balance',
    ];

    if (isSuperAdmin === true) {
        columns.unshift('id', 'orgId');
    }

    const listOptions = {
        "model": ResponseDigitalAccountModel,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/digital-account/admin/search",
        "nameFile": t('digitalAccount.label'),
        "ignoreFields" : columns,
        "modelType" : "AdminDigitalAccount",
        "urlGetSearch" : "/digital-account/admin/search",
    };

    return(
       <>
           <Col lg={12}>
               <Row className="pb-5">
                   <Col lg={12} className="mt-4 card-backend p-4">
                       <h2 className='rokkitt-font'>{t('adminLabel.digitalAccount')}</h2>
                       <Row>
                           <Col lg={4}>
                               <CardDigitalAccount data={responseAllDigitalAccount} label={searchValue} displayValue={changedFilter} />
                           </Col>
                       </Row>
                       <SortableList listOptions={listOptions} onResponse={ResponseCheck} />
                   </Col>
               </Row>
           </Col>
       </>
    );
}