import { Card, Col, Row} from "reactstrap";
import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import CostBearerModel from "../../CostBearer/Models/Response/CostBearerModel";
import useApiCallService from "../../Common/Helpers/ApiCall";
import SortableList from "../../Common/Components/Lists/SortableList";
import AccountingAccountModel from "../../Accounting/Models/Response/ResponseAccountingAccountModel";
import {CheckDecimal} from "../../Common/Helpers/CheckDecimal";
import {useNavigate} from "react-router-dom";
import $ from "jquery";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import { FlashMessage } from "../../Common/Helpers/FlashMessage";
import { CreateFormSwedbankPay } from "../Components/CreateFormSwedbankpay";
import RequestSwedbankPay from "../Models/Request/RequestSwedbanPay";
import ResponseSwedbankPay from "../Models/Response/ResponseSwedbankpay";
import { PAY_PATHS } from "../../config";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import {InfoPageMessage} from "../../Common/Components/Button/InfoPageMessage";
import {LoadingData} from "../../Common/Components/LoadingData";
import {CheckUserPermission} from "../../AppSystem/Helpers/CheckUserPermission";
import {useSelector} from "react-redux";

export function SwedbankPay () {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {ApiCall} = useApiCallService();
    const [key, setKey] = useState(0);
    const { orgData } = useSelector(state => state.auth);

    // API REQUEST
    const [requestPay, setRequestPay] = useState(new RequestSwedbankPay());
    const completeSchema = RequestSwedbankPay.getAttributes(null, true);

    // API RESPONSE
    const [responsePay, setResponsePay] = useState(new ResponseSwedbankPay());
    const [responsePayCopy, setResponsePayCopy] = useState(new ResponseSwedbankPay());

    // API RESPONSE ABORTED PAYMENT
    const [responseAbortedPay, setResponseAborted] = useState('');

    // COST BEARER
    const [costResponse, setCostResponse] = useState('');

    // ACCOUNTING RESPONSES
    const [accountingResponse, setAccountingResponse] = useState(null);

    const [displaySpinner, setDisplaySpinner] = useState(false);

    let permissionCreate = false;
    if (CheckUserPermission("quick-payment.create") || CheckUserPermission("quick-payment")) {
        permissionCreate = true;
    }

    let permissionDelete = false;
    if (CheckUserPermission("quick-payment.delete") || CheckUserPermission("quick-payment")) {
        permissionDelete = true;
    }

    // API CALL TO GET COST BEARER
    useEffect(() => {
        const handleCostResponse = (costResponse) => {
            setCostResponse(costResponse);
        };
        const CostApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/costbearer/list-org?id=" + orgData.orgId,
                };
                await ApiCall(apiCallOptions, handleCostResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        CostApiCall();
    }, []);

    // API CALL TO GET ACCOUNTING
    useEffect(() => {
        const handleAccountingResponse  = (accountingResponse) => {
            setAccountingResponse(accountingResponse);
        };
        const AccountingApiCall = async () => {
            try {
                const apiCallOptions = {
                    method: "get",
                    url: "/accounting/expense-list",
                };
                await ApiCall(apiCallOptions, handleAccountingResponse);
            } catch (error) {
                console.log('error', error);
            }
        };
        AccountingApiCall();
    }, []);

    const costBearerArray = [];
    if (costResponse !== null && costResponse !== '') {
        costResponse.forEach((item) => {
            const cosBearerModel = new CostBearerModel(item);
            costBearerArray.push(cosBearerModel);
        });
    }

    const accountingArray = [];
    if (accountingResponse !== null) {
        accountingResponse.forEach((item) => {
            const accountingModel = new AccountingAccountModel(item);
            accountingArray.push(accountingModel);
        });
    }

    // HANDLE CHANGE
    const HandleChange = async (e, name) => {
        let costBearerIdValue = requestPay.costBearerId;
       // let accountingIdValue = requestPay.accountingId;
        let accountingIdValue = accountingResponse[0].id;
        let descriptionValue = requestPay.description;
        let amountValue = requestPay.amount;
        let vatAmountValue = requestPay.vatAmount;
        let emailValue = requestPay.email;
        let headerValue = requestPay.header;

        if (name === "costBearerId") {
            if (costResponse !== null && costResponse !== '' ) {
                costBearerIdValue = e.target.value;
            }
        }
        /*if (name === "accountingId") {
            if (accountingResponse !== null) {
                accountingIdValue = e.target.value;
            }
        }*/
        if (name === "description") {
            descriptionValue = e.target.value;
        }
        if (name === "header") {
            headerValue = e.target.value;
        }
        if (name === "email") {
            emailValue = e.target.value;
        }
        if (name === "amount") {
            if (e && e.target && e.target.value !== "") {
                amountValue = CheckDecimal(e.target.value);
            }
            else {
                amountValue = 0;
            }
        }
        if (name === "vatAmount") {
            if (e && e.target && e.target.value !== "") {
                vatAmountValue = CheckDecimal(e.target.value);
            } else {
                vatAmountValue = 0;
            }
        }
        setRequestPay((prevFormData) => ({
            ...prevFormData,
            costBearerId: costBearerIdValue,
            accountingId: accountingIdValue,
            description: descriptionValue,
            email: emailValue,
            amount: amountValue,
            vatAmount: vatAmountValue,
            header: headerValue,
        }));
    };

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (responsePay && responsePay.id !== "") {    
        FlashMessage(t('validationMessage.paySuccessfully'), 'flash-messageSuccess');      
            setTimeout(() => {
               // navigate(`${PAY_PATHS.SWEDBANKPAY_INFO}?id=${responsePay.id}`);
               CustomReload();
            }, 2000);
        }
    }, [responsePay]);

    // REDIRECT WHEN FINISH
    useEffect(() => {
        if (responsePayCopy && responsePayCopy.id !== "") {   
            setRequestPay((prevFormData) => ({
                ...prevFormData,              
                email: '', 
            }));
            
            setKey((prevKey) => prevKey + 1);
            FlashMessage(t('validationMessage.paySuccessfully'), 'flash-messageSuccess');      
            setTimeout(() => {
                setDisplaySpinner(false);
                $("#submit").prop("disabled", false);
            }, 2000);
        }
    }, [responsePayCopy]);

    // HANDLE VALIDATE
    const HandleValidate = async () => {
        try {
            await completeSchema.validate(requestPay, { abortEarly: false });
            const emails = requestPay.email.split(/[\n ,;]+/).map(email => email.trim()).filter(email => email);
            //$("#submit").prop("disabled", true);
           // $("#submitCopy").prop("disabled", true);
            setDisplaySpinner(true);

            const apiCallOptions = (email) => ({
                method: "post",
                url: "/swedbank",
                data: { ...requestPay, email: email },
            });
            if (emails.length === 1) {
                try {
                    await ApiCall(apiCallOptions(emails[0]), setResponsePay);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                    $("#submit").prop("disabled", false);
                    $("#submitCopy").prop("disabled", false);
                    console.log('entra en 1')
                }
            } else {
                const promises = emails.map(async (email) => {
                    try {
                        setTimeout(() => {
                        }, 500);
                        await ApiCall(apiCallOptions(email), setResponsePay);
                    } catch (error) {
                        console.log(error);
                        FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                        $("#submit").prop("disabled", false);
                        $("#submitCopy").prop("disabled", false);
                    }
                });
    
                try {
                    await Promise.all(promises);
                } catch (error) {
                    console.log(error);
                    $("#submit").prop("disabled", false);
                    $("#submitCopy").prop("disabled", false);
                }
            }
        } catch (err) {
            console.log(err)
            $("#submit").prop("disabled", false);
            $("#submitCopy").prop("disabled", false);
        }
    }

    // HANDLE VALIDATE
    const HandleValidateCopy = async () => {
        try {
            await completeSchema.validate(requestPay, { abortEarly: false });
            const emails = requestPay.email.split(';').map(email => email.trim()).filter(email => email);
           // $("#submit").prop("disabled", true);
            //$("#submitCopy").prop("disabled", true);
            setDisplaySpinner(true);

            const apiCallOptions = (email) => ({
                method: "post",
                url: "/swedbank",
                data: { ...requestPay, email: email },
            });
            if (emails.length === 1) {
                try {
                    await ApiCall(apiCallOptions(emails[0]), setResponsePayCopy);
                } catch (error) {
                    console.log(error);
                    FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                    $("#submit").prop("disabled", false);
                    $("#submitCopy").prop("disabled", false);
                }
            } else {
                const promises = emails.map(async (email) => {
                    try {
                        setTimeout(() => {
                        }, 500);
                        await ApiCall(apiCallOptions(email), setResponsePayCopy);
                    } catch (error) {
                        console.log(error);
                        FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
                        $("#submit").prop("disabled", false);
                        $("#submitCopy").prop("disabled", false);
                    }
                });
    
                try {
                    await Promise.all(promises);
                } catch (error) {
                    console.log(error);
                    $("#submit").prop("disabled", false);
                    $("#submitCopy").prop("disabled", false);
                }
            }
        } catch (err) {
            console.log(err)
            $("#submit").prop("disabled", false);
            $("#submitCopy").prop("disabled", false);
        }
    }
    const HandleAbortPaymentResponse = (abortedResponse) => {
        if (abortedResponse && abortedResponse.paymentOrder.status !== "" && abortedResponse.paymentOrder.status === "Aborted" ) {    
            FlashMessage(t('validationMessage.payAborted'), 'flash-messageSuccess');  
            setTimeout(() => {
                CustomReload();
            }, 2000); 
        }else{
            FlashMessage( t('validationMessage.sendError') , 'flash-messageError');
            setTimeout(() => {
                CustomReload();
            }, 2000); 
        }
    };
    const HandleAbortPayment = async (id) => {
        try {
            setDisplaySpinner(true);

            const apiCallOptions = {
                method: "delete",
                url: `/swedbank/abort-lrf?id=${id}`,
            };
            try {
                await ApiCall(apiCallOptions, HandleAbortPaymentResponse);
            } catch (error) {
                FlashMessage( t('validationMessage.sendError') , 'flash-messageError');
            }
        } catch (err) {
            console.log(err)
        }
    }

    //// LIST

    const renderActionColumn = (item) => {
        return (
            <>
                <div style={{ display: 'flex', gap: '10px' }}>
                    { permissionDelete === true ?
                        item.displayStatus !== 'Betald' && item.displayStatus !== 'Avbruten' && (

                                /*<CustomButton icon="bx bx-x-circle py-1" iconColor="#F2495C" hoverIconColor='white' /!*hoverButtonColor='#A72020'*!/ iconSize={4} /!*buttonColor="#FA5353" *!/tooltipText={t('button.cancel')} customClasses="rounded border-0 box-red" buttonBorder={false} onClick={() => {HandleAbortPayment(item.swedbankId)}}/>*/
                                <button id={item.id} className="rounded me-2 py-2 border-0 text-red box-red p-custom-button position-relative class-deny"
                                    onClick={() => {
                                        HandleAbortPayment(item.swedbankId)
                                    }} >
                                        <i className="bx bx-x-circle pb-custom align-middle size-icon-custom-button" />
                                        <div className="tooltip-custom-button">
                                            {t('button.cancel') }
                                        </div>
                                </button>
                                    )
                        : ''

                    }

                    <CustomButton icon="bx bx-show py-1" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4} buttonColor="#cde0da" tooltipText={t('button.info')} customClasses="rounded border-0" buttonBorder={false} onClick={() => { navigate(`${PAY_PATHS.SWEDBANKPAY_INFO}?id=${item.id}`) }}  />
                </div>
            </>
        );
    }
    const columns = ["visualId", 'createdAt', 'createdByName', 'orgName', 'email', 'header', 'amount', 'displayStatus'];

    const listOptions = {
        "model": ResponseSwedbankPay,
        'columns': columns,
        "actions": {
            "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/swedbank/search",
        "nameFile": t('payReceive.quickPayments'),
        "ignoreFields" : columns,
        "modelType" : "QuickPayment",
        "urlGetSearch" : "/swedbank/search",
    };

    return(
        <>
            <Col lg={12} className="px-0">
                {permissionCreate === true ?
                    <Row className="px-custom-pay">
                        <Col lg={12} className="py-4 card-backend px-2 px-lg-4">
                            <Row>
                                <Col xs='auto'>
                                    <h2 className="fw-bold rokkitt-font ps-4 ps-lg-0">
                                        {t('payReceive.quickPay')}
                                    </h2>
                                </Col>
                                <InfoPageMessage message={t('infoBox.quickPayment')}/>
                            </Row>
                            {costResponse !== null && costResponse !== '' ?
                                <CreateFormSwedbankPay data={requestPay} costBearerArray={costBearerArray} accountingArray={accountingArray} onChange={HandleChange} onValidate={HandleValidate} onValidateCopy={HandleValidateCopy} displaySpinner={displaySpinner}/>
                                :
                                <LoadingData data={costResponse}/>}

                        </Col>
                    </Row>
                    : ''}

                <Row className="pb-5">
                    <Col lg={12} className="pt-4">
                        <Card className="card-backend p-4">
                            <h2 className="fw-bold rokkitt-font"> {t('payReceive.quickPayments')}</h2>
                            <SortableList
                                listOptions={listOptions}
                                key={key}

                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
        </>
    );
}