import React, {useState, useEffect, useCallback, useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Row , Dropdown, Spinner, DropdownToggle, DropdownMenu, DropdownItem,} from "reactstrap";
import {useTranslation} from "react-i18next";
import $ from 'jquery';
import useApiCallService from '../../Helpers/ApiCall';
import {SaveLocalStorage} from "../../Helpers/SaveLocalStorage";
import {LoadLocalStorage} from "../../Helpers/LoadLocalStorage";
import {UrlStructure} from "../../../AppSystem/Helpers/UrlStructure";
import {random} from "lodash";

/*
EXAMPLE USAGE:

const[apiCallDone, setApiCallDone] = useState(''); //if you need to know the response of the api outside the component use this state

const listOptions = {
    "model": TestModel,                 // Model
    "urlGetSearch" : "/test...",       // url apiCall
};

return (
    <>
        <SortableList
            listOptions={listOptions}
            setApiCallDone={setApiCallDone}           //if you need to know the response of the api outside the component use this state
            // type="model"                          // If you want to use filters by model
        />
    </>
);
EXAMPLE USAGE: WITH EXPORT
  const listOptions = {
        "exportIsAvailable": true,                                 ---> // if you want to show the dropdown for exporting documents you must set this option to true 
        "urlGetListItemsToExport": "/user/admin/search"            ---> // Here you must add the url of the search to get the list of items to export. 
    };                                                                  // NOTE: if you want to export documents both parameters are mandatory ( exportIsAvailable, urlGetListItemsToExport)
*/

const SortableList = ({ 
                          fetchDataFunction,
                          listOptions = {},
                          //handleFilterChange,
                          setApiCallDone = null,
                          type,
                          onResponse = null
                      }) => {
    const { t } = useTranslation();
    let search = LoadLocalStorage("search");
    const currentPageLocal = LoadLocalStorage('currentPage') ?  parseInt(LoadLocalStorage('currentPage')) : 1;
    const itemsPerPageLocal = LoadLocalStorage('itemsPerPage') ? parseInt(LoadLocalStorage('itemsPerPage')) : 10;

    // Configure sorting
    const defaultSortField = listOptions?.sort?.defaultSortField;
    const defaultSortOrder = listOptions?.sort?.defaultSortOrder || 'asc';
    const initialParams = {};
    if (defaultSortField) {
        initialParams.sortField = defaultSortField;
        initialParams.sort = defaultSortOrder;
    }
    const [params, setParams] = useState(initialParams);
    //Configure pagination
    const initialPage = listOptions?.pagination?.initialPage ? listOptions?.pagination?.initialPage : currentPageLocal;
    const initialItemsPerPage = listOptions?.pagination?.initialItemsPerPage ? listOptions?.pagination?.initialItemsPerPage : itemsPerPageLocal;

    // Initialize pagination state
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
    const [totalPages, setTotalPages] = useState(0);
    const paginationControlsPosition = listOptions?.layoutOptions?.pagination?.controlsPosition || 'bottom';
    const paginationView = listOptions?.pagination?.controlsView !== undefined ? listOptions?.pagination?.controlsView : true;

    // Initialize items state
    const [items, setItems] = useState([]);

    // Items to export
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [optionExport, setOptionExport] = useState(null);

    // Initialize loading state
    const [loading, setLoading] = useState(true);


    const [spinnerLoading, setSpinnerLoading] = useState(false);
    // Initialize view mode state
    const defaultLayout = listOptions?.layoutOptions?.grid?.renderGrid ? (listOptions?.availableLayouts && listOptions?.availableLayouts.includes(listOptions?.layout) ? listOptions?.layout : 'table') : 'table';
    const [viewMode, setViewMode] = useState(defaultLayout);
    const canChangeLayout = listOptions?.availableLayouts
        ? listOptions.availableLayouts.length >= 2
        : true; // Default to true if `availableLayouts` is not provided
    // Set table classes
    const tableClass = listOptions?.layoutOptions?.table?.tableClass || 'table ';
    const theadClass = listOptions?.layoutOptions?.table?.theadClass || 'border-bottom-black';
    const tbodyClass = listOptions?.layoutOptions?.table?.tbodyClass || '';
    const trClass = listOptions?.layoutOptions?.table?.trClass || '';
    const thClass = listOptions?.layoutOptions?.table?.thClass || '';
    const tdClass = listOptions?.layoutOptions?.table?.tdClass || '';
    const insideClass = listOptions?.layoutOptions?.table?.insideClass || '';

    //filterOptions
    const filterPosition = listOptions?.layoutOptions?.filter?.filterPosition || 'top';
    // generic filter options
    const allowToggleFilterVisibility = listOptions?.layoutOptions?.filter?.allowToggleVisibility !== undefined ? listOptions.layoutOptions.filter.allowToggleVisibility : false;
    const defaultFilterVisibility = allowToggleFilterVisibility ? (listOptions?.layoutOptions?.filter?.defaultVisibility || false) : true;
    const [showGenericFilterInputs, setShowGenericFilterInputs] = useState(defaultFilterVisibility);
    const viewDropdownPerPage = listOptions?.pagination?.viewDropdownPerPage !== undefined ? listOptions?.pagination?.viewDropdownPerPage : true;

    // Export documents is avaible
    const exportIsAvailable = listOptions?.exportIsAvailable ?? false;
    const urlGetListItemsToExport = listOptions?.urlGetListItemsToExport ?? '';
    const nameFile = listOptions?.nameFile ?? '';
    const ignoreFieldsToExport = listOptions?.ignoreFields ?? [];
    const modelExport = listOptions?.modelExport ?? '';
    const modelType = listOptions?.modelType ?? '';
    

    //const viewDropdownExport = listOptions?.pagination?.viewDropdownExport !== undefined ? listOptions?.pagination?.viewDropdownExport : true;
    const debounceTime = listOptions?.filter?.debounceTime || 1000; //500 1200
    // Initialize columns state
    const [columns, setColumns] = useState([]);
    // CURRENT SORT VALUES
    const [currentSortValues, setCurrentSortValues] = useState({});
    const [totalItems, setTotalItem] = useState('');
    const fetchItemsRef = useRef(null)
    // TODO: FIX IN THE FUTURE
    const [counter, setCounter] = useState(0);
   // console.log('handleFilterChange', handleFilterChange)
    //const addButton = listOptions?.addButton !== undefined ? listOptions?.addButton : false;

    const [filter, setFilter] = useState("");
    const [apiResponseList, setApiResponseList] = useState(null);
    const urlGetSearch = listOptions?.urlGetSearch ?? '';
    //console.log('checkResponse',checkResponse )
    const fetchItems = async () => {
        const fetchApiCall = (response) => {
            if (response) {
                if (onResponse !== null) {
                    onResponse();
                }
                setApiResponseList(response);
                setItems(response.items);
                setTotalPages(response.pagination?.totalPages);
                setTotalItem(response.pagination?.total);
                setError(false);
                setLoading(false);
                setSpinnerLoading(false);
                if (setApiCallDone !== null) {
                    setApiCallDone(response)
                }
            } else {
                if (setApiCallDone !== null) {
                    setApiCallDone(response)
                }
                setError(true);
                setTimeout(() => {
                    setLoading(false);
                }, 10000);
            }
        }

        let url = urlGetSearch;
        let symbol = url.includes('?') ? '&' : '?';
        let sortValue = Object.keys(currentSortValues).length !== 0 ? `&sortField=${currentSortValues.sortField}&sort=${currentSortValues.sort}` : '';
        url += `${symbol}page=${currentPage}&perPage=${itemsPerPage}${sortValue}`;
        const finalUrl= UrlStructure({params,filter,url});
        setSpinnerLoading(true);
        if (finalUrl.includes("digital-account/admin/search")) {
            SaveLocalStorage("search", finalUrl.split("?")[1]);
        }
        
        const apiCallOptions = {
            method: "get",
            url: finalUrl,
        };
        try {
            await ApiCall(apiCallOptions, fetchApiCall);
        } catch (error) {
            console.log(error);
        }
           
    };

    // First ApiCall
    // useEffect(() => {
    //     fetchItems();
    // }, []);

    //Dependencies ApiCall
    useEffect(() => {
        
      // If a call is already in progress, we cancel it.
      if (fetchItemsRef.current) {
        clearTimeout(fetchItemsRef.current);
    }

    // Set a new call to be executed after a delay.
    fetchItemsRef.current = setTimeout(() => {
        fetchItems();
    }, 300);

    // Cleanup when unmounting the component
    return () => {
        if (fetchItemsRef.current) {
            clearTimeout(fetchItemsRef.current);
        }
    };
    }, [currentPage,itemsPerPage, params, currentSortValues, filter ]);

    const handleFilterChange = (newFilters) => {
        if (newFilters) {
            setFilter(`searchCriteria=${newFilters.searchCriteria}`);
        }
    };

    //Check the language to update the sortableList headers
    const [language, setLanguage] = useState(LoadLocalStorage('selectedLanguage'));
    useEffect(() => {
        const checkLanguageChange = () => {

            const currentLanguage = LoadLocalStorage("selectedLanguage");
            if (currentLanguage !== language) {
                setLanguage(currentLanguage);
            }
        };
        const intervalId = setInterval(checkLanguageChange, 600);

        return () => {
            clearInterval(intervalId);
        };
    }, [language]);


    // Generate columns from modelAttributes if provided and from columns if not and if neither is provided create the columns based on known items in the table
    useEffect(() => {
            //get model from listOptions
            const model = listOptions.model;
            //initiate the generatedColumns variable
            let generatedColumns = [];
            const getColumnsFromItems = () => {
                const uniqueKeys = extractKeysFromItems();
                generatedColumns = uniqueKeys.map(key => ({
                    field: key,
                    label: key,
                    value: (item) => item[key] || "not set",
                }));
            }
            //check if model exists and has a getAttributes function
            if (model && typeof model.getAttributes === 'function') {
                //get the attributes from the model
                const modelAttributes = model.getAttributes();
                //if the columns are provided in the listOptions use them
                if (listOptions.columns && Array.isArray(listOptions.columns)) {
                    generatedColumns = listOptions.columns.map(column => {
                        // If it's a string, use it as the field and set label to the value in modelAttributes
                        if (typeof column === 'string') {
                            const label = modelAttributes[column]?.label || column;
                            return {
                                field: column,
                                label,
                                ...modelAttributes[column],
                            };
                            //if it is an object use it as the column configuration
                        } else if (typeof column === 'object') {
                            return column;
                        }
                        return null;
                    }).filter(column => column !== null); // Remove null values if any
                } else {
                    // Generate columns from modelAttributes directly
                    generatedColumns = Object.keys(modelAttributes).map(key => ({
                        field: key,
                        ...modelAttributes[key]
                    }));
                }
            } else {
                //if the model wasn't passed, but we have columns in listOptions use them
                if (listOptions.columns && Array.isArray(listOptions.columns)) {
                    const anyStrings = listOptions.columns.some(column => typeof column === 'string');
                    if (anyStrings) {
                        console.warn("Warning: listOptions.columns contains strings but no model was provided.");
                        getColumnsFromItems();
                    } else {
                        generatedColumns = listOptions.columns;
                    }
                } else {
                    getColumnsFromItems();
                }
            }
            //set the columns state
            setColumns(generatedColumns);

    }, [/*listOptions.model,*//*listOptions.model.getAttributes(),*/ language/*fetchItems*/]); //Only run this effect when the model or fetchDataFunction changes
    //If you put fetchitems in the dependencies, it updates the translations of the table headers but in some tables it creates an infinite loop, that's why it is committed

    // Function to extract keys from items if params are not provided
    const extractKeysFromItems = () => {
        const uniqueKeys = new Set();
        if (items && items.length > 0) {
            items.forEach(item => {
                Object.keys(item).forEach(key => uniqueKeys.add(key));
            });
        }
        return Array.from(uniqueKeys);
    };

    const [error, setError] = useState(false);

    // Fetch items from API
    /*useEffect(() => {
       // if (counter <= 1) {
        if (apiResponseList !== null) {
            console.log('entra en el counter')
            const getData = async () => {
                await fetchDataFunction(params, currentPage, itemsPerPage)
                    .then(response => {
                        if (response) {
                            //setFlag(true);
                            setItems(response.items);
                            setTotalPages(response.pagination?.totalPages);
                            setTotalItem(response.pagination?.total);
                            setError(false);
                            setLoading(false);
                        } else {
                            setError(true);
                            setTimeout(() => {
                                setLoading(false);
                            }, 10000);
                        }
                    }).catch((error) => {
                        console.error('Error fetching data:', error);
                        setError(true);
                    })

                // setCounter(counter + 1);
            }
            getData();
        }

      // }
    }, [/!*currentSortValues, params, currentPage, itemsPerPage, fetchDataFunction, counter*!/ apiResponseList]); // Only run this effect when params, currentPage, itemsPerPage or fetchDataFunction changes*/

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    useEffect(() => {
        if (error === true && loading === false && items?.length === 0) {
            setShowErrorMessage(true);
        } else {
            setShowErrorMessage(false);
        }
    }, [error, loading]);

    // Function to handle sorting when a column header is clicked
    const handleSortChange = (sortField, sort) => {
        // Check if the sortField is among the sortable Fields
        const isSortableField = columns.some(column =>
            (column.sortField || column.field) === sortField && column.sortable !== false
        );
        if (isSortableField) {
            if (type === "model") {
                setParams({...params, sortField, sort});
            }
            else {
                if (Object.keys(currentSortValues).length !== 0) {
                    if (currentSortValues.sortField === sortField && currentSortValues.sort === sort) {
                        sort = sort === 'asc' ? 'desc' : 'asc';
                    }
                }
                setParams(`sortField=${sortField}&sort=${sort}`);
                if (search !== null) {
                    const matchesSort = search.match(/sort=([^&]*)/);
                    const matchesField = search.match(/sortField=([^&]*)/);
                    if (matchesSort !== null && matchesSort[1] !== '' && matchesField !== null && matchesField[1] !== '') {
                        if (matchesField[1] !== sortField) {
                            //REPLACES SORTFIELD WHEN ITS VALUE IS DIFFERENT
                            SaveLocalStorage('search', search.replace(/(sortField=[^&]*)(?:&sort=[^&]*)?/, 'sortField=' + sortField + '&sort=' + sort));
                        } else {
                            //KEEP THE SORTFIELD VALUE AND ONLY CHANGE THE SORT VALUE
                            SaveLocalStorage('search', search.replace(/sort=[^&]*/, 'sort=' + (matchesSort[1] === 'asc' ? 'desc' : 'asc')));
                        }
                    } else {
                        //ADD SORTFIELD AND SORT, TO THE VALUE THAT ALREADY EXISTS IN LOCAL STORAGE
                        SaveLocalStorage('search', search + `&sortField=${sortField}&sort=${sort}`);
                    }
                } else {
                    //SAVE SORTFIELD AND SORT IF IT DOES NOT EXIST
                    SaveLocalStorage('search', `sortField=${sortField}&sort=${sort}`);
                }
                setCurrentSortValues({sortField, sort});
            }
        }
        setCounter(0);
    };

    // Function to toggle between table and grid view
    const toggleViewMode = () => {
        if (listOptions.layoutOptions && listOptions.layoutOptions.grid && canChangeLayout) {
            setViewMode(viewMode === 'table' ? 'grid' : 'table');
        }
    };

    // Function to render the filter UI
    const renderFilterUI = () => {
        if (!handleFilterChange) {
            // Log a warning if renderFilter or listOptions are provided without handleFilterChange
            if (listOptions.layoutOptions && listOptions.layoutOptions.filter && listOptions.layoutOptions.filter.renderFilter) {
                console.warn("Warning: renderFilter or listOptions provided without handleFilterChange.");
            }
            return null;
        }
        // If a custom filter was provided, use it
        if (listOptions.layoutOptions && listOptions.layoutOptions.filter && listOptions.layoutOptions.filter.renderFilter) {
            return listOptions.layoutOptions.filter.renderFilter(handleFilterChange);
        }
        // If listOptions were provided and no custom filter, use the generic filter UI
        else if (listOptions && columns) {
            return generateGenericFilterUI();
        }
        return null;
    };
    const [inputValue, setInputValue] = useState('');

    const debounce = (func) => {
        let inDebounce;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(context, args), debounceTime);
        };
    };
    const [updateFilter, setUpdateFilter] = useState([]);

    const handleInputChangeSearch = (value) => {
        const updatedFilters = { ...params, searchCriteria: value };
        if (value !== '') {
            if (search !== null) {
                const matchesCriteria = search.match(/searchCriteria=([^&]*)/);
                if (matchesCriteria !== null && matchesCriteria[1] !== '') {
                    //WHEN CRITERIA EXISTS
                    SaveLocalStorage('search', search.replace(/searchCriteria=[^&]*/, 'searchCriteria=' + value));
                } else {
                    //WHEN IT DOES NOT EXIST, IT IS ADDED TO THE VALUE THAT ALREADY IS
                    SaveLocalStorage('search', search + (search && search.charAt(search.length -1) !== "&" ? '&' : '') + `searchCriteria=${value}`);
                }
            } else {
                //REMOVE CRITERIA VALUE FROM LOCALSTORAGE
                SaveLocalStorage('search', /*(search && search.charAt(search.length -1) !== "&" ? '&' : '') + */`searchCriteria=${value}`);
            }
        } else {
            //ELIMINATE LOCALSTORAGE CRITERIA
           let finalSearch = '';
            if(search) {
                 finalSearch = search.replace(/searchCriteria=[^&]*/, '');
            }

            if (finalSearch.charAt(0) === "&") {
                finalSearch = finalSearch.substring(1);
            }
            if (finalSearch.charAt(finalSearch.length -1) === "&") {
                finalSearch = finalSearch.slice(0, -1);
            }
            SaveLocalStorage('search', finalSearch);// (/(&?)searchCriteria=[^&]*/, '$1')  (/searchCriteria=[^&]*/, '')
        }
        handleFilterChange(updatedFilters);
        setUpdateFilter(value)
        setCurrentPage(1);
        setCounter(0);
    };

    const debouncedHandleInputChangeSearch = useCallback(
        debounce(handleInputChangeSearch, 500),
        []
    );

    const handleChange = (e) => {
        setInputValue(e.target.value);
        debouncedHandleInputChangeSearch(e.target.value);
    };

    const deleteSearch = () => {
        setUpdateFilter([]);
        const searchCriteria = document.getElementById('searchCriteria');
        if (searchCriteria) {
           // fireEvent.change(searchCriteria, {target: {value: ''}});
            searchCriteria.value = '';
            handleInputChangeSearch('');
            if (Object.keys(params).length !== 0 || (typeof params === "string" && params !== "")) {
                const matchesCriteria = params.match(/searchCriteria=([^&]*)/);
                if (matchesCriteria) {
                    let finalParams = params.replace(/searchCriteria=([^&]*)/, '');
                    if (finalParams.charAt(0) === "&") {
                        finalParams = finalParams.substring(1);
                    }
                    setParams(finalParams);
                }
            }
        }
    }

    useEffect(() => {
        if (search === '') {
            localStorage.removeItem('search');
        }
    }, [search]);

    const searchCriteriaInput = document.getElementById('searchCriteria');

    useEffect(() => {
        search = LoadLocalStorage("search");
        if (search !== null /*&& search !== ''*/) {
            const matchesCriteria = search.match(/searchCriteria=([^&]*)/);
            if (matchesCriteria && searchCriteriaInput) {
                // EVENT IS ONLY FOR THE TEST MODE
                //fireEvent.change(searchCriteriaInput, {target: {value: matchesCriteria[1]}});
                searchCriteriaInput.value = matchesCriteria[1];
                handleInputChangeSearch(matchesCriteria[1]);
            }
        }
    }, [searchCriteriaInput]);

    // Function to generate a generic filter UI based on the columns
    const generateGenericFilterUI = () => {
        return (
            // <Card className="rounded-4 border-bottom-0 box-shadow-custom border-0 my-4">
            //     <CardBody>
            <>
                    <Row>
                        {allowToggleFilterVisibility && (
                            <span className="float-end" style={{width: 'auto'}}>
                                <button className="border-0 bg-transparent" onClick={() => setShowGenericFilterInputs(!showGenericFilterInputs)}>
                                    <i className="fas fa-filter i-color-grow-brown"></i> <i className={`i-color-grow-brown ${showGenericFilterInputs ? "fas fa-sort-up":"fas fa-sort-down"}`}></i>
                                    {/*showGenericFilterInputs ? '<i class="fas fa-sort-down"></i>' : 'Show filters'*/}
                                </button>
                            </span>
                        )}
                    </Row>
                    {showGenericFilterInputs && (
                        <Row>
                            {/* MODEL SEARCH */}
                            {type === "model" ? columns.map(column => {
                                let filterInput = createFilterInput(column);
                                if (filterInput) {
                                    return (
                                        <Col key={column.field} md={3} className="mt-3">
                                            {filterInput}
                                        </Col>
                                    );
                                }
                                return null;
                            }) :
                            <Col md="5" className="mt-3 p-1 input-group-text pr-page-color input-focus-effect ms-1 ms-lg-3" tabIndex="0"> {/******************************/}
                                <i className="bx bx-search" onClick={()=> {$("#searchCriteria").focus()}}></i>
                                <input
                                    id="searchCriteria"
                                    key="searchCriteria"
                                    type="text"
                                    className="border-0 py-0 pr-page-color box-shadow-none w-100"
                                    placeholder={t('sortableList.search')}
                                    onChange={(e)=>{
                                        handleChange(e);
                                        handleFilterChange();
                                    }}
                                />
                                {searchCriteriaInput && searchCriteriaInput.value !== '' ?
                                    <i className='bx bx-x' onClick={deleteSearch}/>
                                    :''}
                            </Col>
                            }
                        </Row>
                    )}
            </>
                // </CardBody>
            // </Card>
        );
    };

    // Function to render the generic pagination controls
    const renderGenericPaginationControls = () => {
        const pages = [];
       // console.log('pages', pages)
        let i = Math.max(1, currentPage - 2);
       // console.log('i del pagination', i)
        const maxPages = Math.min(totalPages, currentPage + 2);
        //console.log('maxPages del pagination', maxPages)
       // console.log('i <= maxPages', i <= maxPages)

        while (i <= maxPages) {
            pages.push(i);
            i += 1;
        }
        return (
            <>
                <Col lg="1" className="text-center text-lg-end p-0">
                    { totalItems !== undefined ?  <span> Total: {totalItems} </span> : ''}
                </Col>
                {paginationView === true ?
                    <>
                        <Col lg="auto" className="text-center text-lg-end p-0">
                            <button className="btn mx-1" aria-label={t('accessibility.goFirst')} onClick={() => {
                                setCurrentPage(1);
                                setCounter(0);
                            }} disabled={currentPage === 1}>
                                <i className="bx bx-chevrons-left fs-4"></i>
                            </button>
                            <button className="btn mx-1" aria-label={t('accessibility.prevPage')} onClick={() => {
                                setCurrentPage(currentPage - 1);
                                setCounter(0);
                            }} disabled={currentPage <= 1}>
                                <i className="bx bx-left-arrow-alt fs-4"></i>
                            </button>
                            {pages.map(page => (
                                <button key={page} className={`btn mx-1 fw-bold ${currentPage === page ? 'active' : ''}`} onClick={() => {
                                    setCurrentPage(page);
                                    SaveLocalStorage('currentPage', page);
                                    setCounter(0);
                                }}>
                                    {page}
                                </button>
                            ))}
                            {/*<span> Page {currentPage} of {totalPages} </span>*/}
                            <button className="btn mx-1" aria-label={t('accessibility.backPage')} onClick={() => {
                                setCurrentPage(currentPage + 1);
                                setCounter(0);
                            }} disabled={currentPage >= totalPages}>
                                <i className="bx bx-right-arrow-alt fs-4"></i>
                            </button>
                            <button className="btn mx-1" aria-label={t('accessibility.goLast')} onClick={() => {
                                setCurrentPage(totalPages);
                                setCounter(0);
                            }} disabled={currentPage === totalPages}>
                                <i className="bx bx-chevrons-right fs-4"></i>
                            </button>
                        </Col>
                    </>
                    : ''}


            </>
        );
    };

// Function to render pagination controls
    const renderPaginationControls = () => {
        const renderPaginationFunction = listOptions.layoutOptions && listOptions.layoutOptions.pagination && listOptions.layoutOptions.pagination.renderPaginationControls;
        if (renderPaginationFunction) {
            return renderPaginationFunction(currentPage, setCurrentPage, totalPages);
        }
        return renderGenericPaginationControls(); // Call the generic renderer
    };

    // Function to render the generic sort dropdown
    const renderGenericSortDropdown = () => {
        // Generate sort options based on the columns, defaulting to sortable unless explicitly set to false
        const sortOptions = columns
            .filter(column => column.sortable !== false) // Default to sortable unless explicitly set to false
            .flatMap(column => {
                // Use sortField if provided, otherwise default to field
                const sortField = column.sortField || column.field;
                return [
                    { value: `${sortField}_asc`, label: `${column.label || column.field} (Asc)` },
                    { value: `${sortField}_desc`, label: `${column.label || column.field} (Desc)` }
                ];
            });
        const currentSort = `${currentSortValues.sortField}_${currentSortValues.sort}`;
        const handleSortDropdownChange = (e) => {
            const [sortField, sort] = e.target.value.split('_');
            handleSortChange(sortField, sort);
            setCounter(0);
        };
        return (
            <select className="form-control arrow-select" value={currentSort} onChange={handleSortDropdownChange}>
                {sortOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
        );
    };

    //Pagination
    //det default options
    const defaultItemsPerPageOptions = [10, 20, 50, 100000]; // Default options

    //det default options to export
    const defaultExportFormatOptions = [ "csv", "xlsx", "pdf"]; // Default options

// Function to render the generic items per page dropdown
    const renderGenericItemsPerPageDropdown = () => {
        const options = listOptions?.pagination?.itemsPerPageOptions || defaultItemsPerPageOptions;
        return (
            <select className="form-select pr-page-color " onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value,10));
                setCurrentPage(1);
                SaveLocalStorage('itemsPerPage',  e.target.value);
                setCounter(0);
            }} defaultValue={initialItemsPerPage}>
                {options.map(option => (
                    <option key={option} value={option}>
                        {option === 100000 ? t('sortableList.all') :
                            <>
                            {option} {t('sortableList.itemsPage')}
                            </>
                        }


                    </option>
                ))}
            </select>
        );
    };


    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEffect(()=>{
    if(apiResponse !== null && optionExport !== null){
            fetchExport(optionExport, apiResponse.items);
    }
    },[apiResponse])
    const { ApiCall } = useApiCallService();

//Function to export files and get list items
    const fetchItemsToExport = async (params) => {
        let url = urlGetListItemsToExport
        if (url.includes('?')) {
            url += `&page=1&perPage=10000`;
        } else {
            url += `?page=1&perPage=10000`;
        }
        if (typeof updateFilter === "string" && params !== "searchCriteria=" && updateFilter !== "") {
            url += `&searchCriteria=${updateFilter}`;
        }

        const apiCallOptions = {
            method: "get",
            url: url,
        };
        try {
            await ApiCall(apiCallOptions, setApiResponse);        
        } catch (error) {
            console.log(error);
        }
    };

    const handleApiResponse  = (apiResponse) => {

        if (apiResponse !== null && apiResponse !== undefined) {
            
            let file;
            let fileName = '';
            if (apiResponse instanceof Blob) {                
                file = apiResponse;
            } 
            else {
                file = new Blob([apiResponse]);
            } 

            let type = file.type.split("/")
            let extension = type[1]

            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0');
            const day = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');

            if (extension === 'pdf' || extension === 'csv') {
                fileName = `${nameFile}_${year}${month}${day}_${hours}${minutes}${seconds}.${extension}`;
            } 
            else {
                fileName = `${nameFile}_${year}${month}${day}_${hours}${minutes}${seconds}.xlsx`;
            }
            
            const urlToFile = URL.createObjectURL(file);                    
            const link = document.createElement('a');
            link.href = urlToFile;
            link.setAttribute('download', fileName);                    
            document.body.appendChild(link);
            link.click();                   
            document.body.removeChild(link);   
        }                     
    };

    const addStatusToList = (list, modelExport) => {
        if (modelExport !== null && modelExport !== undefined && modelExport === 'AdminUsers') {
            return list.map(item => {
                if (!item.hasOwnProperty('status')) {
                    return {
                        visualId: item.visualId,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        pnr: item.pnr,
                        status: 'Aktiv',
                        email: item.email,
                        phone: item.phone
                    };
                }
                return item;
            });
        }

        if (modelExport !== null && modelExport !== undefined && modelExport === 'Pay') {
            return list.map(item => {
                if (!item.hasOwnProperty('date')) {
                    return {
                        visualId: item.visualId,
                        type: item.type,
                        date: 'Snarast ',
                        description: item.description,
                        amount: item.amount,
                        status: item.status,
                        createdByName: item.createdByName
                    };
                }
                return item;
            });
        }
        return list;
    };

    const fetchExport = async (formatType, list) => {
        
        list = addStatusToList(list, modelExport);

        let url = '/export-data'
        const ignoreFields = [];
        let json = {
            format: formatType,
            includeFields: ignoreFieldsToExport ? ignoreFieldsToExport : ignoreFields,
            list: list,
            modelExport : modelExport,
            model : modelType ? modelType : ''
        };
        const apiCallOptions = {
            method: "post",
            url: url,
            data: json,
            responseType : "blob",
        };
        try {
            await ApiCall(apiCallOptions, handleApiResponse);
        
        } catch (error) {
            console.log(error);
        }         
    };

    const handleOptionSelect = async (option) => { 
        fetchItemsToExport(params)
        setOptionExport(option);     
    };

    const renderGenericItemsExportDropdown =  () => { 
        const options = defaultExportFormatOptions;    
        return (
            <div className="p-0">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle  className="bg-green border-0" caret>{t('sortableList.exportData')}</DropdownToggle>
                <DropdownMenu>
                    {options.map(option => (
                        <DropdownItem key={option} onClick={() => handleOptionSelect(option)}>
                            {option}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>                   
        );
        
    };

// Function to render the items per page dropdown
    const renderItemsPerPageDropdown = () => {
        const renderDropdownFunction = listOptions.layoutOptions && listOptions.layoutOptions.pagination && listOptions.layoutOptions.pagination.renderItemsPerPageDropdown;
        if (renderDropdownFunction) {
            return renderDropdownFunction(setItemsPerPage); // Pass setItemsPerPage as an argument
        }
        return renderGenericItemsPerPageDropdown(); // Call the generic renderer
    };

    // Function to render the Export file dropdown
    const renderExportFileDropdown = () => { 
        return renderGenericItemsExportDropdown(); // Call the generic renderer
    };

    // Function to extract column headers from listOptions or all items
    const extractColumnHeaders = () => {
        if (columns) {
            // Use the 'field' property from each column in listOptions
            return columns.map(column => column.field);
        } else {
            // If columns are not provided, extract keys from all items
            const uniqueKeys = new Set();
            items.forEach(item => {
                Object.keys(item).forEach(key => uniqueKeys.add(key));
            });
            return Array.from(uniqueKeys);
        }
    };

    const [checkSort, setCheckSort] = useState(false);
    // Function to handle sorting when a column header is clicked
    const onColumnHeaderClick = (header) => {
        // Find the corresponding column configuration
        const columnConfig = columns.find(column => column.field === header);
        if (columnConfig && columnConfig.sortable !== false) {
            const sortField = columnConfig.sortField || columnConfig.field;
            const newSortOrder = params.sortField === sortField && params.sort === 'asc' ? 'desc' : 'asc';
            handleSortChange(sortField, newSortOrder);
            setTimeout(() => {
                setCheckSort(true);
            },80);
        }
    };

    useEffect(() => {
       if (checkSort === true && search !== null) {
           setParams(search);
           setCheckSort(false);
       }
    }, [checkSort]);

    useEffect(() => {
        if (search !== null) {
            setParams(search);
            setCheckSort(false)
        }
    }, []);

    const createFilterInput = (column) => {
        if (column.filter === false) {
            return null;
        }
        const handleInputChange = (e) => {
            const updatedFilters = { ...params, [column.field]: e.target.value };
            handleFilterChange(updatedFilters);
            setCurrentPage(1);
            setCounter(0);
        };
        const debouncedHandleInputChange = debounce(handleInputChange);
        // Only create filters for columns that have a filter configuration
        if (column.filter) {
            const filterType = column.filter.type || 'text';
            const filterPlaceholder = column.filter.placeholder || column.label;
            return filterType === 'select' ? (
                <select
                    key={column.field}
                    className="form-control"
                    defaultValue={column.filter.default || ''}
                    onChange={debouncedHandleInputChange}
                >
                    <option key={0} value="">{`Select ${filterPlaceholder}`}</option>
                    {column.filter.options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            ) : (
                <input
                    key={column.field}
                    className="form-control"
                    type="text"
                    placeholder={filterPlaceholder}
                    onChange={debouncedHandleInputChange}
                />
            );
        } else {
            return <input
                key={column.field}
                className="form-control"
                type="text"
                placeholder={column.label || column.field}
                onChange={debouncedHandleInputChange}
            />
        }
    };

    const addButton = listOptions.actions && listOptions.actions.addButton;
    const renderAddButton = (item) => {
        return (
            <Row>
                {typeof addButton === "function" ? addButton(item) :''}
            </Row>
        );

    }

    // Generic render table function
    const genericRenderTable = (items) => {
        const columnHeaders = extractColumnHeaders();
        const filterInHeader = listOptions.layoutOptions && listOptions.layoutOptions.table && listOptions.layoutOptions.table.filterInHeader;
        const actionColumn = listOptions.actions && listOptions.actions.actionColumn;
        const checkColumn = listOptions.actions && listOptions.actions.checkColumn;
        const allCheckColumn = listOptions.actions && listOptions.actions.allCheckColumn;
        const actionAllCheckColumn = listOptions.actions && listOptions.actions.actionAllCheckColumn;

        const handleRowClick = (item) => {
            if (listOptions.actions && listOptions.actions.rowClick) {
                listOptions.actions.rowClick(item);
            }
        };
        return (
            <Card className=" border-bottom-0 border-0 px-0 pt-3 mb-4 table-responsive">
                <table className={tableClass}>
                    <thead className={theadClass}>
                    <tr className={trClass}>
                        {checkColumn && (
                            <th key="checkColumn" className={thClass} style={{whiteSpace:"nowrap"}}>
                                {allCheckColumn && allCheckColumn === true && actionAllCheckColumn ?
                                        <input id="checkInput" type="checkbox" onChange={(e) =>{
                                            actionAllCheckColumn(e)
                                        }}
                                        />
                                    :
                                ''}

                            </th>
                        )}
                        {columns.map(column => {
                            let sortLocalStorage = '';
                            let fieldLocalStorage = '';

                            if (search !== null) {
                                const matchesSort = search.match(/sort=([^&]*)/);
                                const matchesField = search.match(/sortField=([^&]*)/);
                                if (matchesSort) {
                                    sortLocalStorage = matchesSort[1];
                                }
                                if (matchesField) {
                                    fieldLocalStorage = matchesField[1];
                                }
                            }
                            return (                              
                                <th
                                    key={column.field}
                                    onClick={() => column.sortable !== false && onColumnHeaderClick(column.field)}
                                    className={`${thClass || ''} ${column.sortable !== false ? "cursor-pointer-custom text-dark text-start" : ""} ${column.thClass ? column.thClass : ''}`}
                                >
                                    {column.label || column.field}
                                    {sortLocalStorage !== '' && fieldLocalStorage !== '' ?
                                        (fieldLocalStorage === column.field ? ( sortLocalStorage === 'asc' ? 
                                        <span>
                                            {' ↑ '}
                                            {spinnerLoading === true ? 
                                            (<Spinner animation="border" variant="success" size="sm" /> ) 
                                            : null}
                                        </span> 
                                        : 
                                        <span>
                                            {' ↓ '}
                                            {spinnerLoading === true ? 
                                            (<Spinner animation="border" variant="success" size="sm" /> ) 
                                            : null}
                                        </span>)  : '')
                                        :
                                    type === "model" ? (params.sortField === column.field ? (params.sort === 'asc' ? ' ↑' : ' ↓') : '') : (currentSortValues.sortField === column.field ? (currentSortValues.sort === 'asc' ? ' ↑' : ' ↓') : '')}
                                </th>
                                )
                        })}
                        {actionColumn && (
                            <th key="actionColumn" className={thClass} style={{whiteSpace:"nowrap"}}></th>
                        )}
                    </tr>
                    {filterInHeader && (
                        <tr className={trClass}>
                            {columns.map(column => (
                                <th key={`${column.field}-filter`} className={thClass }>

                                    {createFilterInput(column)}
                                </th>
                            ))}
                            {actionColumn && (
                                <th key="actionColumn-filter" className={thClass} style={{whiteSpace:"nowrap"}}></th>
                            )}
                        </tr>
                    )}
                    </thead>
                    <tbody className={tbodyClass}>
                    {items.map((item, index) => (
                        <tr key={index} className={trClass} onClick={listOptions.actions && listOptions.actions.rowClick ? () => listOptions.actions.rowClick(item) : undefined} style={listOptions.actions && listOptions.actions.rowClick ? { cursor: 'pointer' } : undefined}>
                            {/*esto es para hacer la parte del los checkbox*/}
                            {checkColumn && (
                                <td key={`${index}-checkColumn`} className={tdClass} style={{whiteSpace:"nowrap"}}>
                                    <div className="float-start">
                                    {checkColumn && item.status !== 'Väntande' ? checkColumn(item.id) : <>&nbsp;</>}
                                    </div>
                                </td>
                            )}
                            {columns.map(column => (
                                <td key={`${index}-${column.field}`} className={tdClass + ' ' +(column.tdClass?column.tdClass.bind(this)(item):'align-middle')}>
                                    {typeof column.value(item) === 'object'
                                        ? <div className={column.insideClass?column.insideClass.bind(this)(item): ''}>{JSON.stringify(column.value(item))}</div>
                                        : <div className={column.insideClass?column.insideClass.bind(this)(item): ''}>{column.value(item)}</div>}
                                </td>
                            ))}
                            {actionColumn && (
                                <td key={`${index}-actionColumn`} className={tdClass + '' + 'align-middle'} style={{whiteSpace:"nowrap"}}>
                                    <div className="float-end">
                                        {actionColumn(item)}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Card>
        );
    };

    // Conditional Rendering
    const renderContentBasedOnViewMode = () => {
        if (!items) {
            return <div className="pb-3 text-center">{t('sortableList.noResults')}</div>;
        }
        return items.length > 0 ? (
            viewMode === 'table' ?
                (listOptions.layoutOptions && listOptions.layoutOptions.table && listOptions.layoutOptions.table.renderTable
                    ? listOptions.layoutOptions.table.renderTable(items, handleSortChange, params.sortField, params.sort)
                    : genericRenderTable(items))
                : (listOptions.layoutOptions && listOptions.layoutOptions.grid && listOptions?.layoutOptions?.grid?.renderGrid
                    ? listOptions?.layoutOptions?.grid?.renderGrid(items)
                    : null)
        ) : ''
    };

    if (showErrorMessage === true && error === true) {
        return <div className="pb-3 text-center">{t('sortableList.apiErrorResult')}</div>;
    }

    if (loading === true) {
        return <Row className='w-100 text-center'>
            <Col lg={2} className='d-flex align-items-center mx-auto' >
                <Spinner color='success' />
                <span className='ps-3 align-self-center'>{t('sortableList.gettingData')}</span>
            </Col>
        </Row>
    }

    return (
        <>
            {(filterPosition === 'top' || filterPosition === 'both') && (
                <Row>
                    <Col md={exportIsAvailable ? 8 : 10}>
                        {renderFilterUI()}
                    </Col>
                    {exportIsAvailable === true ? (
                        <Col xs={6} md={viewDropdownPerPage === true ? 2 : 4} className='text-md-end pt-3 pe-0 pe-md-3 px-2' >
                            {renderExportFileDropdown()}
                        </Col>
                    ) : (
                        ''
                    )}
                    {viewDropdownPerPage === true ? (
                        <Col xs={6} md={2} className='align-self-end p-0 pt-3 pe-0 pe-md-2' >
                            {renderItemsPerPageDropdown()}
                        </Col>
                    ) : (
                        ''
                    )}

                </Row>
            )}
            { renderAddButton() }
            <Row>
                <Col>
                    {listOptions?.layoutOptions && listOptions?.layoutOptions?.grid?.renderGrid && canChangeLayout && (
                        <>
                            <Button
                                onClick={() => setViewMode('table')}
                                className={`border-0 position-relative ${viewMode === 'table' ? 'bg-transparent i-color-grow-brown' : 'bg-transparent text-secondary'}`}
                            >
                                {/!*Table View *!/} <i className="fas fa-list"></i>
                                <div className="tooltip-custom-button"> {t('sortableList.buttonTable')}</div>
                            </Button>
                            {' '}
                            <Button
                                onClick={() => setViewMode('grid')}
                                className={` border-0 position-relative ${viewMode === 'grid' ? 'bg-transparent i-color-grow-brown' : 'bg-transparent text-secondary'}`}
                            >
                                {/!*Grid View*!/}<i className="fas fa-th"></i>
                                <div className="tooltip-custom-button"> {t('sortableList.buttonGrid')}</div>
                            </Button>
                        </>
                    )}
                    {/*{viewDropdownPerPage === true ? (*/}
                    {/*    <div className="float-end">*/}
                    {/*        {renderItemsPerPageDropdown()}*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    {/*    ''*/}
                    {/*)}*/}

                </Col>
            </Row>
            {(paginationControlsPosition === 'top' || paginationControlsPosition === 'both') && (
                    <Row className="justify-content-center">
                        <Col lg="6" className="custom-pagination d-flex align-items-center">
                            {/*<div className="float-end">*/}
                            {renderPaginationControls()}
                            {/*</div>*/}
                        </Col>
                    </Row>)}

                    <Row className={`${typeof addButton === "function" ? '' :'pt-4'} px-3`}>
                        {viewMode === 'grid' &&
                            (listOptions.layoutOptions && listOptions.layoutOptions.filter && listOptions.layoutOptions.filter.renderSortDropdown
                                ? listOptions.layoutOptions.filter.renderSortDropdown(handleSortChange)
                                : renderGenericSortDropdown())
                        }
                        {renderContentBasedOnViewMode()}
                        {/*{ loading ? <Spinner color='success' /> : renderContentBasedOnViewMode()} /!*<Spinner color='success' /> <div>{t('sortableList.loading')}</div>*/}
                    </Row>
            {(paginationControlsPosition === 'bottom' || paginationControlsPosition === 'both') && (
                    <Row className="justify-content-end custom-pagination">
                        {/*<Col lg="4" className="custom-pagination d-flex align-items-center justify-content-between p-0">*/}
                        {renderPaginationControls()}
                        {/*</Col>*/}
                    </Row>
                )}

            {(filterPosition === 'bottom' || filterPosition === 'both') && (
                <Row>
                    {renderFilterUI()}
                </Row>
            )}
        </>
    );
};

SortableList.propTypes = {
    //fetchDataFunction: PropTypes.func.isRequired,
    listOptions: PropTypes.object,
};

SortableList.defaultProps = {
    paginationConfig: {},
};

export default SortableList;
