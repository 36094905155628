import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import './QuickLogin';
import { SaveRedux } from '../../../Common/Helpers/SaveRedux';
import useApiCallService from "../../../Common/Helpers/ApiCall";
import {API_URL, PUBLIC_PATHS, USER_PATHS} from "../../../config"; 
import {Button, Col, Row, Spinner} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import LogoLRF from "../../../AppSystem/assets/img/LRF_Logga.svg";
import $ from 'jquery';
import {CustomReload} from "../../../AppSystem/Helpers/CustomReload";

//import 'https://paiwise.tagroot.io/Events.js'; 
//import 'https://paiwise.tagroot.io/QuickLogin.css';

export  function LoginWithNeuroIdQR() {

    const dispatch = useDispatch();
    const [qrData, setQrData] = useState(null);
    const { t } = useTranslation();
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorMessageResult, setErrorMessageResult] = useState('');
    const { ApiCall } = useApiCallService();
    const [timerExpired, setTimerExpired] = useState(false); 
    const [timeLeft, setTimeLeft] = useState(250); 
    const [tokenTest, setTokenTest] = useState(''); 
    
    const handleReload = () => {
        window.location.reload();
    };

    useEffect(() => {
        const startTime = Date.now();
        const endTime = startTime + timeLeft * 1000;

        const updateTimer = () => {
            const currentTime = Date.now();
            const timeRemaining = Math.floor((endTime - currentTime) / 1000);

            if (timeRemaining <= 0) {
                setTimeLeft(0);
                setTimerExpired(true);
                return;
            }
            setTimeLeft(timeRemaining);
        };

        const intervalId = setInterval(updateTimer, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
       
      const handleApiResponse  = (apiResponse) => {

        if (apiResponse) {
        setQrData(apiResponse.serviceId);

        }else{
          setErrorMessageResult('Something went wrong with sessionId, please contact the kassan administrator');
        }  
      };

        const hubConnection = new HubConnectionBuilder()
                                 .withUrl(`${API_URL}/realTimeUpdateInfo`)
                                 .configureLogging(LogLevel.Information)
                                 .withAutomaticReconnect(0, 0, 10000)
                                 .build();

        async function ConnectSignalR() {
            try {
                await hubConnection
                    .start()
                    .then(() => {
                    })
                    .catch(error => {
                        console.log(`Error: ${error}`);
                    });

                fetchData(hubConnection.connectionId);
                hubConnection.on('ProcessUpdates', (message) => {
                    if (message.isSuccess === true && message.showSpinner === false) {                     
                        SaveRedux(dispatch,'tokenData', message.token);
                        SaveRedux(dispatch,'refreshTokenData', message.refreshToken);
                        SaveRedux(dispatch,'userData', message.user);
                        SaveRedux(dispatch,'rolesData', message.roles);
                        SaveRedux(dispatch,'orgData', message.orgData);
                        hubConnection.stop();
                        setErrorMessageResult('');
                        const link = document.createElement('a');
                        link.href = USER_PATHS.APP;
                        link.rel = 'noopener noreferrer';
                        link.click();
                        setShowSpinner(false);
                    }
                    else if(message.showSpinner !== '' && message.showSpinner === true){
                      setShowSpinner(true);
                    } 
                    else {
                        setErrorMessageResult(message.errorMessage);
                    }
                });
             
            } catch (err) {
                setTimeout(ConnectSignalR, 5000);
            }
        }

        const fetchData = async (connectionIds) => {
            try {

                  const sessionIdCreateRequest = {
                      connectionId: connectionIds
                    };             

                  const apiCallOptions = {
                    method: "post",
                    url: "/neuroid",
                    data: sessionIdCreateRequest    
                    };
                              
                    ApiCall(apiCallOptions, handleApiResponse);  
                                   
            } catch (error) {
              console.log('error', error);
            }
        };

      ConnectSignalR();
        return () => {
           hubConnection.stop();
        };
    }, [dispatch]);

    const [spinnerLogin, setSpinnerLogin] = useState(false);


    //recharge QR Login
    const [divQR, setDivQR] = useState(null);
    setTimeout(() => {
        setDivQR(document.getElementById('quickLoginCode'));
    }, 500);
    //const divQR = document.getElementById('quickLoginCode');
    useEffect(() => {
        //if (divQR && divQR.innerHTML.trim() === '' && errorMessageResult === '') {
        if (divQR && !divQR.hasAttribute("data-done") && errorMessageResult === '') {

            setTimeout(() => {
                CustomReload();
            }, 500);
        }
    }, [divQR]);


    useEffect(() => {

        const handleApiResponse  = (apiResponse) => {

            if (apiResponse) {
            setTokenTest(apiResponse);
    
            }else{
              setErrorMessageResult('Something went wrong with sessionId, please contact the kassan administrator');
            }  
          };
        const fetchData = async () => {
            try {         

                  const apiCallOptions = {
                    method: "get",
                    url: "/realTime",  
                    };
                              
                    ApiCall(apiCallOptions, handleApiResponse);  
                                   
            } catch (error) {
              console.log('error', error);
            }
        };

        //fetchData();
    }, []);


    useEffect(() => {
        //if (divQR && divQR.innerHTML.trim() !== '') {
        if (divQR && divQR.hasAttribute("data-done") && divQR.getAttribute("data-done") == '0') {
            setSpinnerLogin(true)
            $('#containerQR').removeClass('d-none');
        }
        if (errorMessageResult !== ''){
            setSpinnerLogin(true)
            $('#containerQR').removeClass('d-none');
        }
    }, [divQR]);

   
        return(                
           <>
               <div className="container-fluid vh-100 bg-cream">
                   {showSpinner === true ? (
                       <div className="spinner-container">
                           <div className="spinner-wrapper">
                               <Spinner color='success' style={{ width: '5rem', height: '5rem' }} />
                               <p>{t('roterLogin.spinner')}</p>
                           </div>
                       </div>
                   ) : ''}
                   <Row className="vh-100 d-flex align-items-center">
                    <Col md={spinnerLogin === true ? 8 : 5} className="bg-white mx-auto rounded-4" style={{ overflow: 'hidden' }}>
                        <Row>                        
                                <>
                                    {spinnerLogin === true ?
                                        <Col xs={11} md="5" className=" d-flex justify-content-center align-items-center py-5 login-test fade-in z-20">
                                            <img src={LogoLRF} className="w-75  " />
                                        </Col>
                                        : ''}
                                    <Col md={spinnerLogin === true ? 7 : 12} className='py-5'>
                                        <Row>
                                             {!timerExpired ? (
                                            <Col lg="12" className="text-center z-3">
                                                <h3 className="py-2 text-green fw-bold">{t('roterLogin.loginQR')}</h3>
                                            </Col>
                                             ) : (
                                                <Col lg="12" className="text-center z-3">
                                                     <h3 className="py-2 text-red fw-bold">{t('modelLabel.refreshExpired')}</h3>
                                                </Col>
                                             )}
                                            {!timerExpired ? (
                                            <Col lg="12" className={`z-3 text-center px-0 ${showSpinner === true ? 'd-none' : ''}`}>
                                                {spinnerLogin === false ?
                                                    <Spinner color="success" />
                                                    : ''}
                                                <div id="containerQR" className="d-none">
                                                    <div id="quickLoginCode"
                                                        data-serviceid={qrData}
                                                        data-mode="image"
                                                        data-purpose="Du är på väg att logga in på Digitala Kassan hos LRF. Godkänn för att gå vidare." />
                                                </div>
                                                {/* {tokenTest && <div>{tokenTest}</div>}   */}
                                                {errorMessageResult && <div className="error-message">{errorMessageResult}</div>}
                                            </Col>
                                            ) : (
                                                <Col className="text-center py-5 z-3">                                                  
                                                    <Button className="box-green border-0"  onClick={async () => {handleReload();}} >{t('modelLabel.refresh')}</Button>
                                                </Col>
                                            )}
                                            {spinnerLogin === true ?
                                                <div className='test-design z-0 fade-in-2 d-none d-lg-block'></div>
                                                : ''}
                                                
                                        </Row>
                                    </Col>
                                </>     
                        </Row>
                    </Col>
                </Row>
               </div>
           </>
          );
}